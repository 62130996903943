import {
  takeLatest,
  put,
  putResolve,
  select,
} from 'redux-saga/effects';

import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalErrorWithHistory } from 'react-redux-app/lib/modules/api/utils';

import {
  ACTION_UPDATE_BLUE_CHECK_SETTINGS__SUCCESS,
  ACTION_UPDATE_EMAIL__SUCCESS,
} from '../../account/actionTypes';
import { ACTION_BUY_POST__SUCCESS, ACTION_SUBSCRIBE__SUCCESS } from '../../blog/actionTypes';
import { ACTION_BUY_CREDITS_LOCALLY__SUCCESS } from '../../deposit/actionTypes';
import { ACTION_SEND_MESSAGE__SUCCESS } from '../../dialogMessage/actionTypes';
import { ACTION_SEND_GIFT__SUCCESS } from '../../gift/actionTypes';
import {
  ACTION_ACTIVATE_PROFILE_PHOTO__SUCCESS,
  ACTION_DELETE_PROFILE_PHOTO__SUCCESS,
  ACTION_UPDATE_PROFILE_PHOTO__SUCCESS,
} from '../../ownSaleItem/actionTypes/profilePhoto';
import { ACTION_CHANGE_PASSWORD__SUCCESS } from '../../password/actionTypes';
import {
  ACTION_CANCEL_PAYOUT_REQUEST__SUCCESS,
  ACTION_REQUEST_PAYOUT__SUCCESS,
} from '../../revenue/actionTypes';
import { ACTION_BUY_SALE_ITEM__SUCCESS } from '../../saleItem/actionTypes';
import { ACTION_ACCEPT_PRIZE__SUCCESS, ACTION_SPIN_ROULETTE__SUCCESS } from '../actionTypes';

import { getAuthError } from '../selectors/errors';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAuthRequest } from '../actions/auth';


function* reloadAuthData(history, action) {
  try {
    console.consoleLog('reload AuthData by action:', action);

    yield putResolve(actionAuthRequest());

    const error = yield select(getAuthError);
    if (error) {
      maybeHandleCriticalErrorWithHistory(history, error.globalError);
    }
  } catch (e) {
    yield put(actionAddException(e));
  }
}

export default function* saga(history) {
  yield takeLatest([
    ACTION_UPDATE_BLUE_CHECK_SETTINGS__SUCCESS,
    ACTION_UPDATE_EMAIL__SUCCESS,
    ACTION_BUY_POST__SUCCESS,
    ACTION_SUBSCRIBE__SUCCESS,
    ACTION_BUY_CREDITS_LOCALLY__SUCCESS,
    ACTION_SEND_MESSAGE__SUCCESS,
    ACTION_SEND_GIFT__SUCCESS,
    ACTION_ACTIVATE_PROFILE_PHOTO__SUCCESS,
    ACTION_DELETE_PROFILE_PHOTO__SUCCESS,
    ACTION_UPDATE_PROFILE_PHOTO__SUCCESS,
    ACTION_CHANGE_PASSWORD__SUCCESS,
    ACTION_CANCEL_PAYOUT_REQUEST__SUCCESS,
    ACTION_REQUEST_PAYOUT__SUCCESS,
    ACTION_BUY_SALE_ITEM__SUCCESS,
    ACTION_ACCEPT_PRIZE__SUCCESS,
    ACTION_SPIN_ROULETTE__SUCCESS,
  ], reloadAuthData, history);
}
