import { combineReducers } from 'redux';

import errors from './errors';

import {
  ACTION_ADD_REAL_GIFT_OFFERS,
  ACTION_LOAD_GIFTS__SUCCESS,
  ACTION_SELECT_GIFT,
  ACTION_SET_IS_GIFT_SENDING_CONFIRMED,
  ACTION_LOAD_REAL_GIFT_DELIVERY_ADDRESS__SUCCESS,
  ACTION_REJECT_REAL_GIFT__REQUEST,
  ACTION_REJECT_REAL_GIFT__SUCCESS,
  ACTION_REJECT_REAL_GIFT__FAIL,
} from '../actionTypes';


const byId = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_GIFTS__SUCCESS:
      return action.payload.gifts;

    default:
      return state;
  }
};

const order = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_GIFTS__SUCCESS:
      return action.payload.order;

    default:
      return state;
  }
};

const settings = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_GIFTS__SUCCESS:
      return action.payload.settings;

    default:
      return state;
  }
};

const selected = (state = null, action) => {
  switch (action.type) {
    case ACTION_SELECT_GIFT:
      return action.payload.giftId;

    default:
      return state;
  }
};

const isSendingConfirmed = (state = false, action) => {
  switch (action.type) {
    case ACTION_SET_IS_GIFT_SENDING_CONFIRMED:
      return action.payload.isConfirmed;

    default:
      return state;
  }
};

const realGiftOffersById = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_REAL_GIFT_OFFERS:
      return { ...state, ...action.payload.realGiftOffers };

    default:
      return state;
  }
};

const realGiftDeliveryAddress = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_REAL_GIFT_DELIVERY_ADDRESS__SUCCESS:
      return action.payload.deliveryAddress;

    default:
      return state;
  }
};

const isRealGiftBeingRejected = (state = false, action) => {
  switch (action.type) {
    case ACTION_REJECT_REAL_GIFT__REQUEST:
      return true;

    case ACTION_REJECT_REAL_GIFT__SUCCESS:
    case ACTION_REJECT_REAL_GIFT__FAIL:
      return false;

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  order,
  settings,
  selected,
  isSendingConfirmed,
  realGiftOffersById,
  realGiftDeliveryAddress,
  isRealGiftBeingRejected,
  errors,
});
