import { combineReducers } from 'redux';
import _omit from 'lodash/omit';

import errors from './errors';

import {
  ACTION_LOAD_REVENUE_SETTINGS__SUCCESS,
  ACTION_SUBMIT_FISCAL_NUMBER__SUCCESS,
  ACTION_ADD_PAYOUTS,
  ACTION_ADD_AGENCY_PAYOUTS,
  ACTION_CANCEL_PAYOUT_REQUEST__REQUEST,
  ACTION_CANCEL_PAYOUT_REQUEST__SUCCESS,
  ACTION_CANCEL_PAYOUT_REQUEST__FAIL,
  ACTION_LOAD_PAYOUT_COMPLAINT_SETTINGS__SUCCESS,
  ACTION_SET_PAYOUT_COMPLAINT_SETTINGS,
  ACTION_LOAD_LATEST_SALES__SUCCESS,
  ACTION_SET_IS_AMOUNT_RESTRICTED,
} from '../actionTypes';


const settings = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_REVENUE_SETTINGS__SUCCESS:
      return action.payload.settings;

    case ACTION_SUBMIT_FISCAL_NUMBER__SUCCESS:
      return { ...state, isFiscalNumberRequired: false };

    default:
      return state;
  }
};

const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_PAYOUTS:
      return { ...state, ...action.payload.payouts };

    case ACTION_CANCEL_PAYOUT_REQUEST__SUCCESS:
      return _omit(state, action.payload.payoutId);

    default:
      return state;
  }
};

const agencyPayoutsById = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_AGENCY_PAYOUTS:
      return { ...state, ...action.payload.payouts };

    default:
      return state;
  }
};

const isPayoutRequestBeingCanceled = (state = {}, action) => {
  switch (action.type) {
    case ACTION_CANCEL_PAYOUT_REQUEST__REQUEST:
      return { ...state, [action.payload.payoutId]: true };

    case ACTION_CANCEL_PAYOUT_REQUEST__SUCCESS:
    case ACTION_CANCEL_PAYOUT_REQUEST__FAIL:
      return _omit(state, action.payload.payoutId);

    default:
      return state;
  }
};

const payoutComplaintSettings = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_PAYOUT_COMPLAINT_SETTINGS__SUCCESS:
    case ACTION_SET_PAYOUT_COMPLAINT_SETTINGS:
      return action.payload.settings;

    default:
      return state;
  }
};

const latestSales = (state = {}, action) => {
  switch (action.type) {
    case ACTION_LOAD_LATEST_SALES__SUCCESS:
      return action.payload.latestSales;

    default:
      return state;
  }
};

const isAmountRestricted = (state = false, action) => {
  switch (action.type) {
    case ACTION_SET_IS_AMOUNT_RESTRICTED:
      return action.payload.isAmountRestricted;

    default:
      return state;
  }
};

export default combineReducers({
  settings,
  byId,
  agencyPayoutsById,
  isPayoutRequestBeingCanceled,
  payoutComplaintSettings,
  latestSales,
  isAmountRestricted,
  errors,
});
