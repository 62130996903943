import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { useIntl } from 'react-redux-app/lib/modules/i18n/hooks';

import { auraRankPropType } from '../../../auraRank/propTypes';

import AuraRank from '../../../auraRank/components/AuraRank';

import styles from './styles/index.css.json';


const propTypes = {
  isBlueChecked: PropTypes.bool,
  showRed: PropTypes.bool,
  auraRank: auraRankPropType,
  className: PropTypes.string,
};

const BlueCheck = ({
  isBlueChecked = false,
  showRed = false,
  className = null,
  auraRank = null,
}) => {
  const { formatMessage: fm } = useIntl();

  const isRedChecked = !isBlueChecked && showRed;
  const isChecked = isBlueChecked || isRedChecked;

  return (isChecked || auraRank) && (
    <div className={classnames(styles.container, className)}>
      {isChecked && (
        <div
          title={fm({ id: isRedChecked ? 'msg.non-verified-profile' : 'msg.verified-profile' })}
          className={classnames({
            [styles.blueCheck]: isBlueChecked,
            [styles.redCheck]: isRedChecked,
          })}
        />
      )}

      <AuraRank
        rank={auraRank}
        isSmall
        className={classnames({ [styles.auraRank_checked]: isChecked })}
      />
    </div>
  );
};

BlueCheck.propTypes = propTypes;

export default BlueCheck;
