import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import loadable from '@loadable/component';

import { useDidUpdateEffect, useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useCurrentLang } from 'react-redux-app/lib/modules/i18n/hooks';
import { useCallCancellablePromiseAndHandleErrorOrExecute } from '../../../core/api/hooks';
import { useTab } from '../../../core/tabs/hooks';

import { getModifiedLocation } from 'react-redux-app/lib/modules/queryFilter/utils';
import { getMediaViewerCloseUrl } from '../../../mediaViewer/utils';
import { getProfileId } from '../../utils';

import { TAB_QUERY_PARAM } from '../../../core/tabs/constants';
import { MODAL_DIALOG__MODEL } from '../../../model/constants/dialogs';

import { isModalVisibleByName } from 'react-redux-app/lib/modules/modal/selectors';
import { getChatSettings } from '../../../initSettings/selectors';
import { getLoadPartialInitSettingsError } from '../../../initSettings/selectors/errors';
import { getLoadProfileError } from '../../selectors/errors';

import { actionVisitPageRequest } from '../../../auth/actions/visitPage';
import { actionLoadProfileSettingsRequest } from '../../../initSettings/actions/loadPartialInitSettings';
import { actionHideModelDialog } from '../../../model/actions/dialogs/modelDialog';
import { actionLoadProfileRequest } from '../../actions/loadProfile';


const Profile = loadable(() => import('../Profile'));

export default () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const isDialogVisible = useSelector(state => isModalVisibleByName(state, MODAL_DIALOG__MODEL));
  const chatSettings = useShortSelector(getChatSettings);
  const lang = useCurrentLang();

  const tabQueryParam = useTab();

  const id = useMemo(() => getProfileId(location.hash), [location.hash]);

  const [tab, setTab] = useState(() => (id ? null : tabQueryParam));
  const closeUrl = useMemo(() => (
    id && getModifiedLocation(getMediaViewerCloseUrl(location), { [TAB_QUERY_PARAM]: tab })
  ), [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const callActionLoadProfileSettingsRequest = (
    useCallCancellablePromiseAndHandleErrorOrExecute(getLoadPartialInitSettingsError)
  );
  const callActionLoadProfileRequest = (
    useCallCancellablePromiseAndHandleErrorOrExecute(getLoadProfileError)
  );

  useEffect(() => {
    if (isDialogVisible) {
      dispatch(actionHideModelDialog());
    }

    if (!id) {
      return;
    }

    dispatch(actionVisitPageRequest());

    callActionLoadProfileSettingsRequest(
      dispatch(actionLoadProfileSettingsRequest()),
      'header.chat-settings.load'
    );

    callActionLoadProfileRequest(
      dispatch(actionLoadProfileRequest(id)),
      'header.profile.load',
      null,
      () => { navigate(closeUrl); },
      false
    );
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useDidUpdateEffect(() => {
    if (window.chat) {
      window.location.reload(true);
    }
  }, [lang]);

  useDidUpdateEffect(() => {
    if (!id) {
      setTab(tabQueryParam);
    }
  }, [tabQueryParam]);

  return !!id && chatSettings && <Profile id={id} closeUrl={closeUrl} />;
};
