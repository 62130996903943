import { getUri, url } from 'react-redux-app/lib/modules/routing/utils';
import { stripLangFromUri } from '../../../core/i18n/utils';
import { getNotLoggedRedirectUrl } from '../../../auth/utils';
import {
  getUrl,
  getIndexUrl,
  getRegisterUserUrl,
  getRegisterModelUrl,
  getResetPasswordUrl,
  getProfileUrl,
  getVideosUrl,
  getPhotosUrl,
  getErostroiesUrl,
  getAwardsUrl,
  getUnderwearUrl,
  getInterviewsUrl,
  getSiteNewsUrl,
  getLatestItemsUrl,
  getSearchUrl,
  getHowItWorksUrl,
  getVideoChatRulesUrl,
  getRivstreamerUrl,
  getWebcamModelWorkspaceHintsUrl,
  getAccountSettingsUrl,
  getPayoutSettingsUrl,
  getChangePasswordUrl,
  getSpeedTestUrl,
  getCreditsUrl,
  getRevenueUrl,
  getItemsForSaleUrl,
  getBannedUsersUrl,
  getBlackListUrl,
  getDialogsUrl,
  getDialogUrl,
  getRequestsUrl,
  getAppointmentsUrl,
  getUnbanUrl,
  getSignupUrl,
} from '../../utils/url';

import { ACCOUNT_TAB__BLUE_CHECK, ACCOUNT_TAB__PRIVACY } from '../../../account/constants/tabs';
import { REFERRER_QUERY_PARAM } from '../../../auth/constants';
import { DISCOUNT_CODE_COOKIE } from '../../../deposit/constants';
import {
  LANDING_PAGE__CAM_GIRLS,
  LANDING_PAGE__CHAT_ADULTI,
  LANDING_PAGE__CHAT_EROTICA,
  LANDING_PAGE__CHAT_EROTICHE,
  LANDING_PAGE__CHAT_PORNO,
  LANDING_PAGE__CHAT_SEX,
  LANDING_PAGE__CHAT_SEXY_ITALIANE,
  LANDING_PAGE__MATURE_WEBCAM,
  LANDING_PAGE__PORN_CAM,
  LANDING_PAGE__RAGAZZE_IN_CAM,
  LANDING_PAGE__RAGAZZE_IN_WEBCAM,
  LANDING_PAGE__SESSO_IN_CAM,
  LANDING_PAGE__SESSO_WEBCAM,
  LANDING_PAGE__SEXY_CAM,
  LANDING_PAGE__VIDEOCHAT_RAGAZZE,
  LANDING_PAGE__WEBCAM_GAY,
  LANDING_PAGE__WEBCAM_GIRL,
  LANDING_PAGE__WEBCAM_HOT,
  LANDING_PAGE__WEBCAM_SEX,

  LANDING_PAGE__MEDIA_AND_TV,
} from '../../../landingPage/constants';
import { OWN_SALE_ITEMS_TAB__RECORDED_PRIVATES } from '../../../ownSaleItem/constants/tabs';
import { CATEGORY_QUERY_PARAM } from '../../../saleItem/constants';
import { REQUESTS_TAB__APPOINTMENTS } from '../../constants/tabs';

import { getCurrentLangForUrl } from '../../../core/i18n/selectors';


const getUrlWithCategory = (urlArg, queryParams) => url.appendQueryParams(
  urlArg, { [CATEGORY_QUERY_PARAM]: queryParams[CATEGORY_QUERY_PARAM] }
);

export default ({ getState, dispatch }) => (
  function redirectHandler(nextState, successCallback, errorCallback) {
    const queryParams = url.parseQuery(nextState.location.search);
    const langForUrl = getCurrentLangForUrl(getState());
    const redirectMap = {
      '/account_settings.php': getAccountSettingsUrl(langForUrl),
      '/appointment.php': queryParams.model === undefined
        ? getAppointmentsUrl(langForUrl)
        : getRequestsUrl(langForUrl, REQUESTS_TAB__APPOINTMENTS),
      '/awards.php': getAwardsUrl(langForUrl),
      '/bankdetails.php': getPayoutSettingsUrl(langForUrl),
      '/banned_users.php': getBannedUsersUrl(langForUrl),
      '/bluecheck.php': getAccountSettingsUrl(langForUrl, ACCOUNT_TAB__BLUE_CHECK),
      '/chat.php?': getProfileUrl(queryParams.id, langForUrl),
      '/credits.php': url.appendQueryParams(
        getCreditsUrl(langForUrl),
        { [DISCOUNT_CODE_COOKIE]: queryParams.discountCode }
      ),
      '/dialog.php?': getDialogUrl(queryParams.with, langForUrl),
      '/diventare-webcam-girl.php': getRegisterModelUrl(langForUrl),
      '/erotic-stories.php': getUrlWithCategory(getErostroiesUrl(langForUrl), queryParams),
      '/ignored_users.php': getBlackListUrl(langForUrl),
      '/impostazioni-webcam.php': getWebcamModelWorkspaceHintsUrl(langForUrl),
      '/interviews.php': getInterviewsUrl(langForUrl),
      '/latest-items.php': getLatestItemsUrl(langForUrl),
      '/lingerie-search.php': getUnderwearUrl(langForUrl),
      '/login.php': getNotLoggedRedirectUrl(langForUrl, queryParams[REFERRER_QUERY_PARAM]),
      '/messages.php': getDialogsUrl(langForUrl),
      '/model-registration.php': getRegisterModelUrl(langForUrl),
      '/models': getIndexUrl(langForUrl),
      '/newsarchive.php': getSiteNewsUrl(langForUrl),
      '/password_expired.php': getChangePasswordUrl(langForUrl),
      '/password_reset.php?id=': getResetPasswordUrl(queryParams.id, langForUrl),
      '/personal.php': getAccountSettingsUrl(langForUrl),
      '/photo.php': getUrlWithCategory(getPhotosUrl(langForUrl), queryParams),
      '/privacy-settings.php': getAccountSettingsUrl(langForUrl, ACCOUNT_TAB__PRIVACY),
      '/privacy_settings.php': getAccountSettingsUrl(langForUrl, ACCOUNT_TAB__PRIVACY),
      '/profile.php?': getProfileUrl(queryParams.id, langForUrl),
      '/racconti-erotici.php': getUrlWithCategory(getErostroiesUrl(langForUrl), queryParams),
      '/racconti-erotici-sitemap.php': getUrlWithCategory(getErostroiesUrl(langForUrl), queryParams),
      '/recorded-privates.php': getItemsForSaleUrl(
        langForUrl, OWN_SALE_ITEMS_TAB__RECORDED_PRIVATES
      ),
      '/registration.php?type=model': getRegisterModelUrl(langForUrl),
      '/registration.php?type=user': getRegisterUserUrl(langForUrl),
      '/revenue.php': getRevenueUrl(langForUrl),
      '/revenue_report.php': getRevenueUrl(langForUrl),
      '/rivphone.php': getIndexUrl(langForUrl),
      '/rivstreamer.php': getRivstreamerUrl(langForUrl),
      '/rules/chat.php': getVideoChatRulesUrl(langForUrl),
      '/rules/index.php': getHowItWorksUrl(langForUrl),
      '/search.php': getSearchUrl(langForUrl),
      '/speed-test.php': getSpeedTestUrl(langForUrl),
      '/signup.php': getSignupUrl(langForUrl),
      '/unban.php': getUnbanUrl(langForUrl),
      '/unbanme.php': getUnbanUrl(langForUrl),
      '/video.php': getUrlWithCategory(getVideosUrl(langForUrl), queryParams),
      '/video-amatoriali.php': getUrlWithCategory(getVideosUrl(langForUrl), queryParams),
      '/webcam-model-workspace-hints.php': getWebcamModelWorkspaceHintsUrl(langForUrl),

      '/cam-girls.php': getUrl(langForUrl, LANDING_PAGE__CAM_GIRLS),
      '/chat-adulti.php': getUrl(langForUrl, LANDING_PAGE__CHAT_ADULTI),
      '/chat-erotica.php': getUrl(langForUrl, LANDING_PAGE__CHAT_EROTICA),
      '/chat-erotiche.php': getUrl(langForUrl, LANDING_PAGE__CHAT_EROTICHE),
      '/chat-porno.php': getUrl(langForUrl, LANDING_PAGE__CHAT_PORNO),
      '/chat-sex.php': getUrl(langForUrl, LANDING_PAGE__CHAT_SEX),
      '/chat-sexy-italiane.php': getUrl(langForUrl, LANDING_PAGE__CHAT_SEXY_ITALIANE),
      '/mature-webcam.php': getUrl(langForUrl, LANDING_PAGE__MATURE_WEBCAM),
      '/porn-cam.php': getUrl(langForUrl, LANDING_PAGE__PORN_CAM),
      '/ragazze-in-cam.php': getUrl(langForUrl, LANDING_PAGE__RAGAZZE_IN_CAM),
      '/ragazze-in-webcam.php': getUrl(langForUrl, LANDING_PAGE__RAGAZZE_IN_WEBCAM),
      '/sesso-in-cam.php': getUrl(langForUrl, LANDING_PAGE__SESSO_IN_CAM),
      '/sesso-webcam.php': getUrl(langForUrl, LANDING_PAGE__SESSO_WEBCAM),
      '/sexy-cam.php': getUrl(langForUrl, LANDING_PAGE__SEXY_CAM),
      '/videochat-ragazze.php': getUrl(langForUrl, LANDING_PAGE__VIDEOCHAT_RAGAZZE),
      '/webcam-gay.php': getUrl(langForUrl, LANDING_PAGE__WEBCAM_GAY),
      '/webcam-girl.php': getUrl(langForUrl, LANDING_PAGE__WEBCAM_GIRL),
      '/webcam-hot.php': getUrl(langForUrl, LANDING_PAGE__WEBCAM_HOT),
      '/webcam-sex.php': getUrl(langForUrl, LANDING_PAGE__WEBCAM_SEX),

      '/media-and-tv.php': getUrl(langForUrl, LANDING_PAGE__MEDIA_AND_TV),
    };
    const strippedCurrentUri = stripLangFromUri(getUri(nextState.location));
    const redirectUrl = Object.keys(redirectMap).find(uri => strippedCurrentUri.startsWith(uri));

    if (redirectUrl) {
      errorCallback({ pathname: redirectMap[redirectUrl], state: { status: 301 } });
    } else {
      successCallback();
    }
  }
);
