import { dom } from 'react-redux-app/lib/modules/core/utils';
import preloadImage from '../../core/utils/preloadImage';

import {
  ACTION_CLEAR_DIALOG_MESSAGE_STATE,
  ACTION_LOAD_DIALOG_MESSAGES__REQUEST,
  ACTION_LOAD_DIALOG_MESSAGES__SUCCESS,
  ACTION_LOAD_DIALOG_MESSAGES__FAIL,
  ACTION_ADD_DIALOG_MESSAGES,
  ACTION_ADD_OLD_DIALOG_MESSAGES_IDS,
  ACTION_ADD_NEW_DIALOG_MESSAGES_IDS,
  ACTION_CLEAR_NEW_MESSAGE_COUNT,
  ACTION_ADD_ATTACHMENT,
} from '../actionTypes';

import { ITEMS_SCHEMA } from '../../core/schemas/items';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


export const actionClearDialogMessageState = () => ({
  type: ACTION_CLEAR_DIALOG_MESSAGE_STATE,
});

const actionAddDialogMessages = dialogMessages => ({
  type: ACTION_ADD_DIALOG_MESSAGES,
  payload: { dialogMessages },
});

const actionAddOldDialogMessageIds = dialogMessages => ({
  type: ACTION_ADD_OLD_DIALOG_MESSAGES_IDS,
  payload: { dialogMessages },
});

const actionAddNewDialogMessageIds = dialogMessages => ({
  type: ACTION_ADD_NEW_DIALOG_MESSAGES_IDS,
  payload: { dialogMessages },
});

export const actionClearNewMessageCount = () => ({
  type: ACTION_CLEAR_NEW_MESSAGE_COUNT,
});

export const actionAddAttachment = attachment => ({
  type: ACTION_ADD_ATTACHMENT,
  payload: { attachment },
});

const actionLoadDialogMessagesSuccess = () => ({
  type: ACTION_LOAD_DIALOG_MESSAGES__SUCCESS,
});

const actionLoadDialogMessagesFail = error => ({
  type: ACTION_LOAD_DIALOG_MESSAGES__FAIL,
  payload: { error },
});

export const actionLoadDialogMessagesRequest = (
  peerId, beforeMessageId, afterMessageId, limit = 15
) => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_DIALOG_MESSAGES__REQUEST,
    });

    const params = { limit };
    if (afterMessageId) {
      params.afterMessageId = afterMessageId;
    }
    if (beforeMessageId) {
      params.beforeMessageId = beforeMessageId;
    }

    return apiClientHelper.get(
      `message/dialog_messages/${peerId}`,
      { params, retry: true },
      ITEMS_SCHEMA
    ).then(
      ({ result: dialogMessageIds, entities }) => {
        const messages = entities.items || {};

        dialogMessageIds.items.reverse();
        dispatch(actionAddDialogMessages(messages));

        if (!dom.isBrowser()) {
          dispatch(actionAddOldDialogMessageIds(dialogMessageIds));

          return dispatch(actionLoadDialogMessagesSuccess());
        }

        // Preload attachment thumbs
        const promises = [];
        Object.keys(messages).forEach(id => {
          if (messages[id].attachments.length > 0) {
            promises.push(
              ...messages[id].attachments.map(attachment => preloadImage(attachment.thumbUrl))
            );
          }
        });

        return Promise.all(promises).then(() => {
          if (afterMessageId) {
            dispatch(actionAddNewDialogMessageIds(dialogMessageIds));
          } else {
            dispatch(actionAddOldDialogMessageIds(dialogMessageIds));
          }

          dispatch(actionLoadDialogMessagesSuccess());
        });
      },
      error => {
        dispatch(actionLoadDialogMessagesFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
