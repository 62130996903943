import { getIsIndexPageAlias } from '../../app/utils/url';
import { getLandingPage } from '../utils';

import {
  ACTION_LOAD_LANDING_PAGE__REQUEST,
  ACTION_LOAD_LANDING_PAGE__SUCCESS,
  ACTION_LOAD_LANDING_PAGE__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionLoadLandingPageSuccess = landingPage => ({
  type: ACTION_LOAD_LANDING_PAGE__SUCCESS,
  payload: { landingPage },
});

const actionLoadLandingPageFail = error => ({
  type: ACTION_LOAD_LANDING_PAGE__FAIL,
  payload: { error },
});

export const actionLoadLandingPageRequest = (isIndex = false, pathname = null) => (
  (dispatch, getState, { apiClientHelper, history: { location } }) => {
    dispatch({
      type: ACTION_LOAD_LANDING_PAGE__REQUEST,
    });

    let page;
    if (!isIndex) {
      page = getLandingPage(location);
    } else {
      page = getIsIndexPageAlias({ pathname }) ? getLandingPage({ pathname }) : 'index';
    }

    return apiClientHelper.get(
      `static_page/${encodeURIComponent(page)}`,
      { retry: true }
    ).then(
      landingPage => {
        dispatch(actionLoadLandingPageSuccess(landingPage));
      },
      error => {
        dispatch(actionLoadLandingPageFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
