import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { DropdownItem } from 'reactstrap';
import classnames from 'classnames';

import useCurrentLangForUrl from '../../../core/i18n/hooks/useCurrentLangForUrl';
import useShowModal from '../../../core/hooks/useShowModal';
import { useIsModelHub, useIsRivcam } from '../../../initSettings/hooks';

import { l } from 'react-redux-app/lib/modules/i18n/utils';
import { getRegisterModelUrl, getAgencyLoginUrl } from '../../utils/url';

import { actionShowLoginDialog } from '../../../auth/actions/dialogs/loginDialog';
import { actionShowUserRegistrationDialog } from '../../../auth/actions/dialogs/userRegistrationDialog';

import { Button } from 'react-redux-app/lib/modules/button/components';
import WhiteButton from '../../../core/components/buttons/WhiteButton';
import MenuLink from '../MenuLink';

import styles from './styles/index.css.json';


const propTypes = {
  isLoginLink: PropTypes.bool,
  isDropdownItem: PropTypes.bool,
  className: PropTypes.string,
};

const AuthButtons = ({ isLoginLink = false, isDropdownItem = false, className = null }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const langForUrl = useCurrentLangForUrl();
  const isModelHub = useIsModelHub();
  const isRivcam = useIsRivcam();

  const showModal = useShowModal();

  const getButton = (ButtonComponent, onClick, valueLangKey) => {
    const button = (
      <ButtonComponent
        type="button"
        onClick={onClick}
        className={classnames(styles.button, className)}
      >
        {l(valueLangKey)}
      </ButtonComponent>
    );

    return isDropdownItem ? <DropdownItem tag="div">{button}</DropdownItem> : button;
  };

  return pathname !== getAgencyLoginUrl(langForUrl) && (
    <>
      {!isRivcam && getButton(
        Button,
        () => { navigate(getRegisterModelUrl(langForUrl)); },
        'action.become-model'
      )}

      {getButton(
        Button,
        () => {
          if (isModelHub) {
            navigate(getRegisterModelUrl(langForUrl));
          } else {
            dispatch(actionShowUserRegistrationDialog());
          }
        },
        'action.register-for-free'
      )}

      {!isLoginLink ? (
        getButton(WhiteButton, () => { dispatch(actionShowLoginDialog()); }, 'action.log-in')
      ) : (
        <MenuLink
          to="/"
          onClick={showModal(actionShowLoginDialog())}
          className={className}
          activeClassName={null}
        >
          {l('action.log-in')}
        </MenuLink>
      )}
    </>
  );
};

AuthButtons.propTypes = propTypes;

export default AuthButtons;
