import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { auraRankPropType } from '../../propTypes';

import {
  AURA_RANK__BRONZE,
  AURA_RANK__SILVER,
  AURA_RANK__GOLD,
} from '../../constants';

import styles from './styles/index.css.json';


const rankToClassNameMap = {
  [AURA_RANK__BRONZE]: styles.bronze,
  [AURA_RANK__SILVER]: styles.silver,
  [AURA_RANK__GOLD]: styles.gold,
};

const rankToClassNameSmallMap = {
  [AURA_RANK__BRONZE]: styles.bronze_small,
  [AURA_RANK__SILVER]: styles.silver_small,
  [AURA_RANK__GOLD]: styles.gold_small,
};

const propTypes = {
  rank: auraRankPropType,
  isSmall: PropTypes.bool,
  className: PropTypes.string,
};

const AuraRank = ({ rank = null, isSmall = false, className = null }) => rank && (
  <div
    className={classnames(
      isSmall ? rankToClassNameSmallMap[rank] : rankToClassNameMap[rank],
      className
    )}
  />
);

AuraRank.propTypes = propTypes;

export default AuraRank;
