import { dom } from 'react-redux-app/lib/modules/core/utils';
import preloadImage from '../../core/utils/preloadImage';
import { getType, setModelOrder } from '../utils';

import {
  ACTION_LOAD_CHAT_GALLERY_MODELS__REQUEST,
  ACTION_LOAD_CHAT_GALLERY_MODELS__SUCCESS,
  ACTION_LOAD_CHAT_GALLERY_MODELS__FAIL,
} from '../actionTypes';

import { CHAT_GALLERY_MODELS_SCHEMA } from '../schemas';

import { getModelLimit } from '../selectors/models';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import {
  actionAddModels,
  actionSetModelChatStatuses,
  actionSetAllowedTypes,
  getModelsAndStatuses,
} from './models';


const actionLoadChatGalleryModelsSuccess = () => ({
  type: ACTION_LOAD_CHAT_GALLERY_MODELS__SUCCESS,
});

const actionLoadChatGalleryModelsFail = error => ({
  type: ACTION_LOAD_CHAT_GALLERY_MODELS__FAIL,
  payload: { error },
});

export const actionLoadChatGalleryModelsRequest = (filter, isWithBonus = true, limit = null) => (
  (dispatch, getState, { apiClientHelper, getCookie }) => {
    dispatch({
      type: ACTION_LOAD_CHAT_GALLERY_MODELS__REQUEST,
    });

    return apiClientHelper.get(
      'models/chat_gallery',
      { params: { type: filter, limit: limit || getModelLimit(getState()) }, retry: true },
      CHAT_GALLERY_MODELS_SCHEMA
    ).then(
      ({ result, entities }) => {
        const items = entities.items || {};

        const { models, statuses } = getModelsAndStatuses(items);

        const allowedTypes = {};
        const type = getType(filter);
        result.models.forEach(modelId => { allowedTypes[modelId] = { [type]: true }; });

        dispatch(actionAddModels(models));
        dispatch(actionSetModelChatStatuses(statuses));
        dispatch(actionSetAllowedTypes(allowedTypes));

        if (!dom.isBrowser()) {
          setModelOrder(dispatch, getState, getCookie, result.models, result, isWithBonus);

          return dispatch(actionLoadChatGalleryModelsSuccess());
        }

        // Preload model photo thumbs
        const promises = Object.keys(items).map(id => preloadImage(items[id].imageUrl));

        return Promise.all(promises).then(() => {
          setModelOrder(dispatch, getState, getCookie, result.models, result, isWithBonus);

          dispatch(actionLoadChatGalleryModelsSuccess());
        });
      },
      error => {
        dispatch(actionLoadChatGalleryModelsFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
