import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useCurrentLang } from 'react-redux-app/lib/modules/i18n/hooks';
import { useQueryParam } from 'react-redux-app/lib/modules/routing/hooks';
import useCurrentLangForUrl from '../../../core/i18n/hooks/useCurrentLangForUrl';

import { stripLangFromUri } from '../../../core/i18n/utils';
import {
  getProfileUrl,
  getVideosUrl,
  getPhotosUrl,
  getErostroiesUrl,
  getInterviewUrl,
} from '../../utils/url';

import { PAGE_QUERY_PARAM } from '../../../core/loadableItems/constants';
import { ORDER_BY_QUERY_PARAM } from '../../../core/switch/constants';
import { CATEGORY_QUERY_PARAM } from '../../../saleItem/constants';
import {
  PATH_PROFILE,
  PATH_VIDEOS,
  PATH_PHOTOS,
  PATH_EROSTORIES,
  PATH_INTERVIEW,
} from '../../constants/url';

import {
  getSeoForCurrentLang,
  getBaseHostWithProtocol,
} from '../../../initSettings/selectors';
import {
  getInterviewId,
  getInterviewTitle,
  getInterviewDescription,
  getModelNick as getInterviewModelNick,
} from '../../../interview/selectors/interview';
import {
  getModelId,
  getModelNick,
  getModelDescription,
  getModelThumbUrl,
} from '../../../model/selectors';
import { getErostoryCategories } from '../../../saleItem/selectors/erostory';
import { getPrivatePhotoCategories } from '../../../saleItem/selectors/privatePhoto';
import { getVideoCategories } from '../../../saleItem/selectors/video';


const DEFAULT_URI = '_default';
const DEFAULT_LANG = '*';

export default () => {
  const { pathname } = useLocation();

  const baseHostWithProtocol = useShortSelector(getBaseHostWithProtocol);
  const { config, frontendToSeoPathMap } = useShortSelector(getSeoForCurrentLang);
  const modelId = useShortSelector(getModelId);
  const modelNick = useShortSelector(getModelNick);
  const modelDescription = useShortSelector(getModelDescription);
  const modelThumbUrl = useShortSelector(getModelThumbUrl);
  const interviewId = useShortSelector(getInterviewId);
  const interviewTitle = useShortSelector(getInterviewTitle);
  const interviewDescription = useShortSelector(getInterviewDescription);
  const interviewModelNick = useShortSelector(getInterviewModelNick);
  const videoCategories = useShortSelector(getVideoCategories);
  const photoCategories = useShortSelector(getPrivatePhotoCategories);
  const erostoryCategories = useShortSelector(getErostoryCategories);
  const lang = useCurrentLang();
  const langForUrl = useCurrentLangForUrl();

  const category = parseInt(useQueryParam(CATEGORY_QUERY_PARAM), 10);
  const orderBy = useQueryParam(ORDER_BY_QUERY_PARAM);
  const page = useQueryParam(PAGE_QUERY_PARAM);

  const getCategoryName = categories => categories.find(c => c.id === category)?.name;
  const isContentPageWithCategory = getUrl => pathname === getUrl(langForUrl) && !!category;
  const isModelProfilePage = () => pathname === getProfileUrl(modelId, langForUrl);
  const isInterviewPage = () => pathname === getInterviewUrl(interviewId, langForUrl);

  const getStrippedCurrentUri = () => {
    if (isContentPageWithCategory(getVideosUrl)) {
      return `/${PATH_VIDEOS}?${CATEGORY_QUERY_PARAM}=`;
    }
    if (isContentPageWithCategory(getPhotosUrl)) {
      return `/${PATH_PHOTOS}?${CATEGORY_QUERY_PARAM}=`;
    }
    if (isContentPageWithCategory(getErostroiesUrl)) {
      return `/${PATH_EROSTORIES}?${CATEGORY_QUERY_PARAM}=`;
    }
    if (isModelProfilePage()) {
      return `/${PATH_PROFILE}`;
    }
    if (isInterviewPage()) {
      return `/${PATH_INTERVIEW}`;
    }

    return stripLangFromUri(pathname);
  };

  const replaceParams = value => {
    if (!value) {
      return null;
    }

    let replacedValue = value
      .replace('#contentEncoding#', 'utf-8')
      .replace('#_lang#', lang);

    if (isContentPageWithCategory(getVideosUrl)) {
      replacedValue = replacedValue
        .replace('#category#', getCategoryName(videoCategories));
    }

    if (isContentPageWithCategory(getPhotosUrl)) {
      replacedValue = replacedValue
        .replace('#category#', getCategoryName(photoCategories));
    }

    if (isContentPageWithCategory(getErostroiesUrl)) {
      replacedValue = replacedValue
        .replace('#category#', getCategoryName(erostoryCategories));
    }

    if (isModelProfilePage()) {
      replacedValue = replacedValue
        .replace('#nick#', modelNick)
        .replace('#desc#', modelDescription.split('.')[0])
        .replace('#ogDescription#', modelDescription || '#')
        .replace('#profileURL#', `${baseHostWithProtocol}${pathname}`)
        .replace('#profileImageCrop#', modelThumbUrl);
    }

    if (isInterviewPage()) {
      replacedValue = replacedValue
        .replace('#interview#', interviewTitle)
        .replace('#desc#', interviewDescription)
        .replace('#nick#', interviewModelNick);
    }

    return replacedValue;
  };

  let seoUri = frontendToSeoPathMap[getStrippedCurrentUri()];
  if (!seoUri || !config[seoUri]) {
    if (!config[DEFAULT_URI]) {
      return null;
    }

    seoUri = DEFAULT_URI;
  }

  const helmetProps = {};
  const names = [];

  do {
    config[seoUri].tags.forEach(
      ({
        name,
        tag: tagName,
        attrs,
        content,
      }) => {
        if (names.includes(name)) {
          return;
        }

        if (content) {
          const value = content[lang] || content[DEFAULT_LANG];
          if (!value) {
            return;
          }

          helmetProps[tagName] = replaceParams(value);
        } else {
          const tag = {};
          let hasEmptyValue = false;
          attrs.forEach(attr => {
            const value = attr.value[lang] || attr.value[DEFAULT_LANG];
            if (!value) {
              hasEmptyValue = true;
              return;
            }

            tag[attr.name] = replaceParams(value);
          });
          if (hasEmptyValue) {
            return;
          }

          if (!Array.isArray(helmetProps[tagName])) {
            helmetProps[tagName] = [];
          }
          helmetProps[tagName].push(tag);
        }

        names.push(name);
      }
    );

    seoUri = config[seoUri].override;
  } while (seoUri);

  if (orderBy || page) {
    const name = 'robots';
    const content = 'noindex, follow';
    const robotsTag = helmetProps.meta?.find(tag => tag.name === name);

    if (robotsTag) {
      robotsTag.content = content;
    } else {
      if (!helmetProps.meta) {
        helmetProps.meta = [];
      }
      helmetProps.meta.push({ name, content });
    }
  }

  return <Helmet {...helmetProps} />;
};
