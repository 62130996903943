import React from 'react';

import {
  getContentRemovalUrl,
  getEuDsaUrl,
  getDmcaComplaintUrl,
  getVideoChatRulesUrl,
  get182257Url,
  getTermsUrl,
  getPrivacyPolicyUrl,
  getRefundUrl,
  getSiteNewsUrl,
} from '../utils/url';

import Zendesk from '../../auth/components/Zendesk';


export const helpLinks = [
  { isItem: true, itemNode: <Zendesk />, to: 'zendesk' },
  { to: getContentRemovalUrl(), linkValue: 'msg.content-removal' },
  { to: getDmcaComplaintUrl(), linkValue: 'msg.dmca-complaint' },
  { to: getEuDsaUrl(), linkValue: 'msg.eu-digital-services-act' },
];

export const rulesLinks = [
  { to: getVideoChatRulesUrl(), linkValue: 'msg.video-chat-rules' },
  { to: get182257Url(), linkValue: 'msg.record-keeping-requirements' },
  { to: getTermsUrl(), linkValue: 'msg.terms-and-conditions' },
  { to: getPrivacyPolicyUrl(), linkValue: 'msg.privacy-policy' },
  { to: getRefundUrl(), linkValue: 'msg.refund-policy' },
  { to: getSiteNewsUrl(), linkValue: 'msg.news' },
];
