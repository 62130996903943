import { dom } from 'react-redux-app/lib/modules/core/utils';
import { getUri } from 'react-redux-app/lib/modules/routing/utils';
import { getLangFromRequestUri } from '../core/i18n/utils';
import { getExpiredRedirectUrl } from '../auth/utils';
import { getIndexUrl } from './utils/url';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';
import {
  // API_BAD_REQUEST_ERROR,
  API_FORBIDDEN_ERROR,
  // API_NOT_FOUND_ERROR,
  API_UNAUTHORIZED_ERROR,
  API_UNKNOWN_ERROR,
  // API_BAD_REQUEST_BODY_ERROR,
  // API_OPERATION_FAILED_ERROR,
  WL_TEMPLATE_NOT_SUPPORTED,
  MONEY_OPERATIONS_WRONG_SITE,
} from '../core/api/constants/errors';

import { getIsAura, getAuraUrl } from '../initSettings/selectors';


export default reduxStore => (location, redirectFunction) => {
  const redirect = url => {
    if (!dom.isBrowser()) {
      redirectFunction(url);
    } else {
      dom.redirect(url);
    }
  };

  const indexRedirectFunction = () => {
    redirect(getIndexUrl(getLangFromRequestUri(getUri(location))));
  };

  return {
    // [API_BAD_REQUEST_ERROR]: () => redirectFunction(INTERNAL_ERROR_URI),
    // [API_NOT_FOUND_ERROR]: () => redirectFunction(INTERNAL_ERROR_URI),
    // [API_BAD_REQUEST_BODY_ERROR]: () => redirectFunction(INTERNAL_ERROR_URI),
    [API_UNAUTHORIZED_ERROR]: () => {
      const currentLocation = getUri(location);
      redirect(getIsAura(reduxStore.getState())
        ? getAuraUrl(reduxStore.getState())
        : getExpiredRedirectUrl(getLangFromRequestUri(currentLocation), currentLocation));
    },
    [API_FORBIDDEN_ERROR]: indexRedirectFunction,
    [API_UNKNOWN_ERROR]: () => redirectFunction(INTERNAL_ERROR_URI),
    // [API_OPERATION_FAILED_ERROR]: () => redirectFunction(INTERNAL_ERROR_URI),

    [WL_TEMPLATE_NOT_SUPPORTED]: indexRedirectFunction,
    [MONEY_OPERATIONS_WRONG_SITE]: params => { redirect(`https://${params.availableDomain}`); },
  };
};
