import { getAuraWebcamSettingsUrl, getWebcamSettingsUrl } from '../../utils/url';

import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import { getIsAuthenticated, getIsNeedSetWebcamType } from '../../../auth/selectors';
import { getIsAuraLayout } from '../../../initSettings/selectors';


export default ({ getState, dispatch }) => (
  function notChangePasswordRequiredHandler(nextState, successCallback, errorCallback) {
    if (
      getIsAuthenticated(getState())
      && getIsNeedSetWebcamType(getState())
    ) {
      errorCallback(getIsAuraLayout(getState())
        ? getAuraWebcamSettingsUrl(getCurrentLangForUrl(getState()))
        : getWebcamSettingsUrl(getCurrentLangForUrl(getState())));
    } else {
      successCallback();
    }
  }
);
