import { createSelector } from 'reselect';

import {
  getIndexUrl,
  getVideosUrl,
  getPhotosUrl,
  getErostroiesUrl,
  getUnderwearUrl,
  getInterviewsUrl,
  getAwardsUrl,
} from '../utils/url';

import { getIsUnderwearForbidden } from '../../auth/selectors';
import {
  getIsForBoys,
  getIsPrivateContentForbidden,
  getIsVideoSite,
  getIsRivcam,
} from '../../initSettings/selectors';


export const getMenuItems = createSelector(
  getIsPrivateContentForbidden,
  getIsUnderwearForbidden,
  getIsForBoys,
  getIsVideoSite,
  (isPrivateContentForbidden, isUnderwearForbidden, isForBoys, isVideoSite) => (
    [
      {
        langKey: isVideoSite ? 'msg.videos' : 'msg.models',
        footerLangKey: null,
        path: getIndexUrl(),
      },
      {
        langKey: 'msg.videos',
        footerLangKey: 'msg.amateur-video',
        path: getVideosUrl(),
      },
      {
        langKey: 'msg.photos',
        footerLangKey: 'msg.amateur-photo',
        path: getPhotosUrl(),
      },
      {
        langKey: 'msg.stories',
        footerLangKey: 'msg.erotic-stories',
        path: getErostroiesUrl(),
      },
      {
        langKey: 'msg.underwear',
        footerLangKey: 'msg.underwear',
        path: getUnderwearUrl(),
      },
      {
        langKey: 'msg.interviews',
        footerLangKey: 'msg.sexy-interviews',
        path: getInterviewsUrl(),
      },
      {
        langKey: 'msg.awards',
        footerLangKey: null,
        path: getAwardsUrl(),
      },
    ].filter(({ path }) => {
      switch (path) {
        case getVideosUrl():
          return !isPrivateContentForbidden && !isVideoSite;

        case getPhotosUrl():
          return !isPrivateContentForbidden;

        case getUnderwearUrl():
          return !isUnderwearForbidden;

        case getAwardsUrl():
          return !isForBoys;

        default:
          return true;
      }
    })
  )
);

const products = [getVideosUrl(), getPhotosUrl(), getErostroiesUrl(), getUnderwearUrl()];
const excluded = [getInterviewsUrl(), getAwardsUrl()];

export const getCollapsedMenuItems = createSelector(
  getMenuItems,
  getIsVideoSite,
  getIsRivcam,
  (menuItems, isVideoSite, isRivcam) => {
    const result = [];
    const productMenuItems = menuItems.filter(({ path }) => products.includes(path));

    let isProductsPushed = false;
    menuItems.forEach(item => {
      if (!isVideoSite && !isRivcam && excluded.includes(item.path)) {
        return;
      }

      if (!products.includes(item.path)) {
        result.push(item);
      } else if (!isProductsPushed) {
        isProductsPushed = true;

        result.push({
          langKey: 'msg.products',
          products: productMenuItems,
        });
      }
    });

    return result;
  }
);

export const getExcludedMenuItems = createSelector(
  getMenuItems,
  getIsVideoSite,
  getIsRivcam,
  (menuItems, isVideoSite, isRivcam) => menuItems.filter(({ path }) => (
    !isVideoSite && !isRivcam && excluded.includes(path)
  ))
);
