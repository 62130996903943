export const ACTION_ADD_MEDIA_VIEWER_ITEMS = 'ACTION_ADD_MEDIA_VIEWER_ITEMS';
export const ACTION_SET_MEDIA_VIEWER_ITEMS = 'ACTION_SET_MEDIA_VIEWER_ITEMS';

export const ACTION_LOAD_MEDIA_VIEWER_ITEMS__REQUEST = 'ACTION_LOAD_MEDIA_VIEWER_ITEMS__REQUEST';
export const ACTION_LOAD_MEDIA_VIEWER_ITEMS__SUCCESS = 'ACTION_LOAD_MEDIA_VIEWER_ITEMS__SUCCESS';
export const ACTION_LOAD_MEDIA_VIEWER_ITEMS__FAIL = 'ACTION_LOAD_MEDIA_VIEWER_ITEMS__FAIL';

export const ACTION_CLEAR_MEDIA_VIEWER_ITEMS = 'ACTION_CLEAR_MEDIA_VIEWER_ITEMS';

export const ACTION_LOAD_MEDIA_VIEWER_ITEM_INFO__REQUEST = 'ACTION_LOAD_MEDIA_VIEWER_ITEM_INFO__REQUEST';
export const ACTION_LOAD_MEDIA_VIEWER_ITEM_INFO__SUCCESS = 'ACTION_LOAD_MEDIA_VIEWER_ITEM_INFO__SUCCESS';
export const ACTION_LOAD_MEDIA_VIEWER_ITEM_INFO__FAIL = 'ACTION_LOAD_MEDIA_VIEWER_ITEM_INFO__FAIL';

export const ACTION_LEAVE_COMMENT__REQUEST = 'ACTION_LEAVE_COMMENT__REQUEST';
export const ACTION_LEAVE_COMMENT__SUCCESS = 'ACTION_LEAVE_COMMENT__SUCCESS';
export const ACTION_LEAVE_COMMENT__FAIL = 'ACTION_LEAVE_COMMENT__FAIL';

export const ACTION_DELETE_COMMENT__REQUEST = 'ACTION_DELETE_COMMENT__REQUEST';
export const ACTION_DELETE_COMMENT__SUCCESS = 'ACTION_DELETE_COMMENT__SUCCESS';
export const ACTION_DELETE_COMMENT__FAIL = 'ACTION_DELETE_COMMENT__FAIL';

export const ACTION_SET_REPLY_RECEIVER = 'ACTION_SET_REPLY_RECEIVER';
export const ACTION_REMOVE_REPLY_RECEIVER = 'ACTION_REMOVE_REPLY_RECEIVER';
