export const REVENUE_TAB__AGENCY_PAYOUTS = 'agencyPayouts';
export const REVENUE_TAB__LATEST_SALES = 'latestSales';
export const REVENUE_TAB__PAYOUTS = 'payouts';
export const REVENUE_TAB__REVENUE = 'revenue';

export const PAYOUT_TAB__BANK_ACCOUNT_DETAILS = 'bankAccountDetails';
export const PAYOUT_TAB__REVOLUT = 'revolut';
export const PAYOUT_TAB__XCARD = 'xcard';

export const payoutTabs = [
  PAYOUT_TAB__BANK_ACCOUNT_DETAILS,
  // PAYOUT_TAB__XCARD,
  PAYOUT_TAB__REVOLUT,
];
