import { useDispatch } from 'react-redux';

import { useLatestSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useCallCancellablePromiseAndHandleErrorOrExecute } from '../../core/api/hooks';
import { useIsAuthenticated } from '../../auth/hooks';

import { getAvailableGiftIds } from '../selectors';
import { getLoadGiftsError } from '../selectors/errors';

import { actionShowLoginDialog } from '../../auth/actions/dialogs/loginDialog';
import { actionSelectGift } from '../actions';
import { actionLoadGiftsRequest } from '../actions/loadGifts';
import { actionShowSendGiftDialog } from '../actions/dialogs/sendGiftDialog';


export default (isReal = false, callback = null) => {
  const dispatch = useDispatch();

  const availableGiftIds = useLatestSelector(getAvailableGiftIds);
  const isAuthenticated = useIsAuthenticated();

  const callActionLoadGiftsRequest = (
    useCallCancellablePromiseAndHandleErrorOrExecute(getLoadGiftsError)
  );
  return (id, nick) => {
    if (!isAuthenticated) {
      dispatch(actionShowLoginDialog());
      return;
    }

    callActionLoadGiftsRequest(
      dispatch(actionLoadGiftsRequest(isReal)),
      isReal ? 'header.real-gifts.load' : 'header.gifts.load',
      () => {
        callback?.();
        dispatch(actionSelectGift(id ? null : availableGiftIds.current[0]));
        dispatch(actionShowSendGiftDialog(id, nick, isReal));
      }
    );
  };
};
