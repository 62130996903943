import { ACTION_CHANGE_FILE_UPLOAD_PROGRESS } from '../actionTypes';


export default (state = { percent: 0 }, action) => {
  switch (action.type) {
    case ACTION_CHANGE_FILE_UPLOAD_PROGRESS:
      return action.payload;

    default:
      return state;
  }
};
