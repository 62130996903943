import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException, url } from 'react-redux-app/lib/modules/routing/utils';
import { getRedirectUrl, getIsZendesk, showDialogs } from '../../../auth/utils';
import { getRegisterUserUrl, getRegisterModelUrl } from '../../utils/url';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';
import {
  ERROR_CODE_QUERY_PARAM,
  AUTH_TOKEN_QUERY_PARAM,
  SOCIAL_TOKEN_QUERY_PARAM,
  REDIRECT_URL_QUERY_PARAM,
} from '../../../core/social/constants';
import { SOCIAL_USER_WITH_EMAIL_EXISTS, socialErrorLangKeys } from '../../../core/social/constants/errors';
import { SHOW_DIALOGS_QUERY_PARAM } from '../../../auth/constants';

import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import { getCompleteSocialAttachmentError } from '../../../account/selectors/errors';
import {
  getUser,
  getLoginInfo,
  getIsAuthenticated,
  getPrizesAfterDeposit,
} from '../../../auth/selectors';
import { getLoginByAuthTokenError } from '../../../auth/selectors/errors';

import { actionAddGlobalError } from 'react-redux-app/lib/modules/globalError/actions';
import { actionCompleteSocialAttachmentRequest } from '../../../account/actions/completeSocialAttachment';
import { actionLoginByAuthTokenRequest } from '../../../auth/actions/loginByAuthToken';
import { actionShowRegistrationDialog } from '../../../auth/actions/dialogs/registrationDialog';
import { actionShowSocialLoginErrorDialog } from '../../../auth/actions/dialogs/socialLoginErrorDialog';


export default ({ getState, dispatch }) => (
  function socialLoginHandler(nextState, successCallback, errorCallback) {
    const queryParams = url.parseQuery(nextState.location.search);
    const errorCode = queryParams[ERROR_CODE_QUERY_PARAM];
    const authToken = queryParams[AUTH_TOKEN_QUERY_PARAM];
    const socialToken = queryParams[SOCIAL_TOKEN_QUERY_PARAM];
    const redirectUrl = queryParams[REDIRECT_URL_QUERY_PARAM];
    const isZendesk = getIsZendesk(redirectUrl);

    if (!errorCode && !authToken && !socialToken) {
      successCallback();
      return;
    }

    const langForUrl = getCurrentLangForUrl(getState());
    const registerUrls = [getRegisterUserUrl(langForUrl), getRegisterModelUrl(langForUrl)];

    if (errorCode) {
      if (errorCode === SOCIAL_USER_WITH_EMAIL_EXISTS) {
        dispatch(actionShowSocialLoginErrorDialog());
      } else {
        dispatch(actionAddGlobalError({
          text: { id: socialErrorLangKeys[errorCode] },
          header: { id: 'header.social-login' },
        }));
      }

      successCallback();
    } else if (authToken && !getIsAuthenticated(getState())) {
      dispatch(
        actionLoginByAuthTokenRequest(authToken, isZendesk)
      ).then(
        () => {
          if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
            return;
          }

          const error = getLoginByAuthTokenError(getState());
          if (error) {
            if (!maybeHandleCriticalError(nextState.location, error.globalError, errorCallback)) {
              dispatch(actionAddGlobalError({
                text: error.globalError.message,
                header: { id: 'header.social-login' },
              }));
              successCallback();
            }
            return;
          }

          const loginInfo = getLoginInfo(getState());
          const resultUrl = getRedirectUrl(
            getState, getUser(getState()), loginInfo, !isZendesk && redirectUrl
          );
          if (resultUrl) {
            errorCallback(resultUrl.startsWith('https://') ? resultUrl : url.appendQueryParams(
              resultUrl, { [SHOW_DIALOGS_QUERY_PARAM]: 1 }
            ));
            return;
          }

          showDialogs(dispatch, getState, loginInfo, getPrizesAfterDeposit(getState()));

          successCallback();
        }
      ).catch(error => {
        console.consoleError('socialLoginHandler failed', error);
        errorCallback(INTERNAL_ERROR_URI);
      });
    } else if (socialToken) {
      if (getIsAuthenticated(getState())) {
        dispatch(
          actionCompleteSocialAttachmentRequest(socialToken)
        ).then(
          () => {
            if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
              return;
            }

            const error = getCompleteSocialAttachmentError(getState());
            if (error) {
              if (maybeHandleCriticalError(nextState.location, error.globalError, errorCallback)) {
                return;
              }

              dispatch(actionAddGlobalError({
                text: error.globalError.message,
                header: { id: 'header.social-login' },
              }));
            }

            successCallback();
          }
        ).catch(error => {
          console.consoleError('socialLoginHandler failed', error);
          errorCallback(INTERNAL_ERROR_URI);
        });
      } else if (!registerUrls.includes(nextState.location.pathname)) {
        dispatch(actionShowRegistrationDialog());
      }
      successCallback();
    } else {
      successCallback();
    }
  }
);
