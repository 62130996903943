import _get from 'lodash/get';


const errorsSelector = state => _get(state, 'account.errors');
export const getLoadAccountError = state => (
  _get(errorsSelector(state), 'loadAccountError')
);
export const getUpdateAccountError = state => (
  _get(errorsSelector(state), 'updateAccountError')
);
export const getUpdateEmailError = state => (
  _get(errorsSelector(state), 'updateEmailError')
);
export const getConfirmEmailError = state => (
  _get(errorsSelector(state), 'confirmEmailError')
);
export const getOptoutEmailError = state => (
  _get(errorsSelector(state), 'optoutEmailError')
);
export const getResetEmailError = state => (
  _get(errorsSelector(state), 'resetEmailError')
);
export const getLoadPreferencesError = state => (
  _get(errorsSelector(state), 'loadPreferencesError')
);
export const getUpdatePreferencesError = state => (
  _get(errorsSelector(state), 'updatePreferencesError')
);
export const getLoadWebcamSettingsError = state => (
  _get(errorsSelector(state), 'loadWebcamSettingsError')
);
export const getUpdateWebcamSettingsError = state => (
  _get(errorsSelector(state), 'updateWebcamSettingsError')
);
export const getLoadBlueCheckSettingsError = state => (
  _get(errorsSelector(state), 'loadBlueCheckSettingsError')
);
export const getUpdateBlueCheckSettingsError = state => (
  _get(errorsSelector(state), 'updateBlueCheckSettingsError')
);
export const getUpdateBlueCheckAutoConvertError = state => (
  _get(errorsSelector(state), 'updateBlueCheckAutoConvertError')
);
export const getLoadVideochatExternalApplicationSettingsError = state => (
  _get(errorsSelector(state), 'loadVideochatExternalApplicationSettingsError')
);
export const getGenerateVideochatExternalApplicationSettingsError = state => (
  _get(errorsSelector(state), 'generateVideochatExternalApplicationSettingsError')
);
export const getSendSpeedTestResultError = state => (
  _get(errorsSelector(state), 'sendSpeedTestResultError')
);
export const getLoadPrivacyError = state => (
  _get(errorsSelector(state), 'loadPrivacyError')
);
export const getUpdatePrivacyError = state => (
  _get(errorsSelector(state), 'updatePrivacyError')
);
export const getAddGeoFilterError = state => (
  _get(errorsSelector(state), 'addGeoFilterError')
);
export const getDeleteGeoFilterError = state => (
  _get(errorsSelector(state), 'deleteGeoFilterError')
);
export const getLoadPrivacyAddressInformationError = state => (
  _get(errorsSelector(state), 'loadPrivacyAddressInformationError')
);
export const getUpdatePrivacyAddressInformationError = state => (
  _get(errorsSelector(state), 'updatePrivacyAddressInformationError')
);
export const getDeleteAccountError = state => (
  _get(errorsSelector(state), 'deleteAccountError')
);
export const getLoadModelProfileError = state => (
  _get(errorsSelector(state), 'loadModelProfileError')
);
export const getUpdateModelProfileError = state => (
  _get(errorsSelector(state), 'updateModelProfileError')
);
export const getLoadTelegramSettingsError = state => (
  _get(errorsSelector(state), 'loadTelegramSettingsError')
);
export const getConnectToTelegramError = state => (
  _get(errorsSelector(state), 'connectToTelegramError')
);
export const getUpdateTelegramNotificationsError = state => (
  _get(errorsSelector(state), 'updateTelegramNotificationsError')
);
export const getDisconnectFromTelegramError = state => (
  _get(errorsSelector(state), 'disconnectFromTelegramError')
);
export const getLoadPushSettingsError = state => (
  _get(errorsSelector(state), 'loadPushSettingsError')
);
export const getUpdatePushSettingsError = state => (
  _get(errorsSelector(state), 'updatePushSettingsError')
);
export const getLoadSocialSettingsError = state => (
  _get(errorsSelector(state), 'loadSocialSettingsError')
);
export const getAttachSocialError = state => (
  _get(errorsSelector(state), 'attachSocialError')
);
export const getCompleteSocialAttachmentError = state => (
  _get(errorsSelector(state), 'completeSocialAttachmentError')
);
export const getDetachSocialError = state => (
  _get(errorsSelector(state), 'detachSocialError')
);
export const getAddWhiteListedUserError = state => (
  _get(errorsSelector(state), 'addWhiteListedUserError')
);
export const getDeleteWhiteListedUserError = state => (
  _get(errorsSelector(state), 'deleteWhiteListedUserError')
);
export const getLoadBlogSettingsError = state => (
  _get(errorsSelector(state), 'loadBlogSettingsError')
);
export const getUpdateBlogSettingsError = state => (
  _get(errorsSelector(state), 'updateBlogSettingsError')
);
export const getLoadFlowerDeliverySettingsError = state => (
  _get(errorsSelector(state), 'loadFlowerDeliverySettingsError')
);
export const getUpdateFlowerDeliverySettingsError = state => (
  _get(errorsSelector(state), 'updateFlowerDeliverySettingsError')
);
