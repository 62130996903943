/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useIsRootMounted } from '../../../app/hooks';

import { getTrafficJunkyCodeTemplate } from '../../../initSettings/selectors';


const propTypes = {
  depositId: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
};

const TrafficJunkyTracker = ({ depositId, amount }) => {
  const trackerCodeTemplate = useShortSelector(getTrafficJunkyCodeTemplate);
  const isRootMounted = useIsRootMounted();

  const trackerCode = trackerCodeTemplate
    .replaceAll('#depositId#', depositId)
    .replaceAll('#amount#', amount);

  return isRootMounted && <div dangerouslySetInnerHTML={{ __html: trackerCode }} />;
};

TrafficJunkyTracker.propTypes = propTypes;

export default TrafficJunkyTracker;
