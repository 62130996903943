import _get from 'lodash/get';

import { dom } from 'react-redux-app/lib/modules/core/utils';

import config from '../../../../../../../config';


export default (domain, rcid, path) => {
  const rcidInt = parseInt(rcid, 10);

  const serverApiDomain = !dom.isBrowser() && config.apiDomain.server;
  const apiDomain = serverApiDomain
    || _get(config.apiDomain, [domain, 'path', path]) || _get(config.apiDomain, [domain, 'domain'])
    || _get(config.apiDomain, `default.path.${path}`) || _get(config.apiDomain, 'default.domain')
    || domain;
  const scheme = serverApiDomain ? 'http' : 'https';

  return `${scheme}://${apiDomain}/api/v1/${domain}${rcidInt ? `_${rcidInt}` : ''}/${path}`;
};
