import { ACTION_SET_IS_MENU_OPENED } from '../actionTypes/setIsMenuOpened';


export default (state = false, action) => {
  switch (action.type) {
    case ACTION_SET_IS_MENU_OPENED:
      return !state;

    default:
      return state;
  }
};
