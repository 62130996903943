import { getFilterByName } from 'react-redux-app/lib/modules/queryFilter/utils';
import { getFilter } from '../../core/loadableItems/utils';

import { SORT_DIRECTION__DESC } from 'react-redux-app/lib/modules/table/constants';

import {
  ACTION_LOAD_PAYING_MEMBERS__REQUEST,
  ACTION_LOAD_PAYING_MEMBERS__SUCCESS,
  ACTION_LOAD_PAYING_MEMBERS__FAIL,
  ACTION_SET_PAYING_MEMBERS,
  ACTION_SET_PAYING_MEMBER_IDS,
} from '../actionTypes';

import {
  PAYING_MEMBERS_GRID_NAME,
  PAYING_MEMBERS_ORDER_OPTION__LAST_SALE_DATE,
} from '../constants';

import { ITEMS_SCHEMA } from '../../core/schemas/items';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionSetPayingMembers = payingMembers => ({
  type: ACTION_SET_PAYING_MEMBERS,
  payload: { payingMembers },
});

const actionSetPayingMemberIds = payingMemberIds => ({
  type: ACTION_SET_PAYING_MEMBER_IDS,
  payload: { payingMemberIds },
});

const actionLoadPayingMembersSuccess = () => ({
  type: ACTION_LOAD_PAYING_MEMBERS__SUCCESS,
});

const actionLoadPayingMembersFail = (location, error) => ({
  type: ACTION_LOAD_PAYING_MEMBERS__FAIL,
  payload: { location, error },
});

export const actionLoadPayingMembersRequest = () => (
  (dispatch, getState, { apiClientHelper, history: { location } }) => {
    dispatch({
      type: ACTION_LOAD_PAYING_MEMBERS__REQUEST,
    });

    const {
      page = 1,
      nick,
      limit = 10,
    } = getFilterByName(location, PAYING_MEMBERS_GRID_NAME) || {};

    const params = {
      sortDirection: SORT_DIRECTION__DESC,
      sortField: PAYING_MEMBERS_ORDER_OPTION__LAST_SALE_DATE,
      limit,
    };

    if (nick) {
      params.nick = nick;
    }

    const { orderBy } = getFilter(location);
    if (orderBy) {
      params.sortField = orderBy;
    }

    return apiClientHelper.get(
      `modelhub/peers/${page}`,
      { params },
      ITEMS_SCHEMA
    ).then(
      ({ result, entities }) => {
        dispatch(actionSetPayingMembers(entities.items || {}));

        dispatch(actionSetPayingMemberIds(result));
        dispatch(actionLoadPayingMembersSuccess());
      },
      error => {
        dispatch(actionLoadPayingMembersFail(location, error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
