import { dom } from 'react-redux-app/lib/modules/core/utils';
import preloadImage from '../../../core/utils/preloadImage';

import { MODAL_DIALOG__GIFT_EVENT } from '../../constants/dialogs';

import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';


export const actionShowGiftEventDialog = (
  src,
  link,
  isHappyBirthday = false,
  survey = null
) => dispatch => {
  const action = actionShowModal(MODAL_DIALOG__GIFT_EVENT, {
    src,
    link,
    isHappyBirthday,
    survey,
  });

  return !dom.isBrowser() ? dispatch(action) : preloadImage(src).then(() => { dispatch(action); });
};

export const actionHideGiftEventDialog = () => dispatch => (
  dispatch(actionHideModal(MODAL_DIALOG__GIFT_EVENT))
);
