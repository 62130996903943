export const ACTION_SET_DIALOGS = 'ACTION_SET_DIALOGS';

export const ACTION_SET_DIALOG_IDS = 'ACTION_SET_DIALOG_IDS';

export const ACTION_LOAD_DIALOGS__REQUEST = 'ACTION_LOAD_DIALOGS__REQUEST';
export const ACTION_LOAD_DIALOGS__SUCCESS = 'ACTION_LOAD_DIALOGS__SUCCESS';
export const ACTION_LOAD_DIALOGS__FAIL = 'ACTION_LOAD_DIALOGS__FAIL';

export const ACTION_DIALOGS_MOUNTED = 'ACTION_DIALOGS_MOUNTED';
export const ACTION_DIALOGS_UNMOUNTED = 'ACTION_DIALOGS_UNMOUNTED';
export const ACTION_ENABLE_DIALOGS = 'ACTION_ENABLE_DIALOGS';
export const ACTION_DISABLE_DIALOGS = 'ACTION_DISABLE_DIALOGS';

export const ACTION_DELETE_DIALOG__REQUEST = 'ACTION_DELETE_DIALOG__REQUEST';
export const ACTION_DELETE_DIALOG__SUCCESS = 'ACTION_DELETE_DIALOG__SUCCESS';
export const ACTION_DELETE_DIALOG__FAIL = 'ACTION_DELETE_DIALOG__FAIL';
