import { combineReducers } from 'redux';

import loadDepositSettingsError from './loadDepositSettingsError';
import buyCreditsLocallyError from './buyCreditsLocallyError';
import buyCreditsExternallyError from './buyCreditsExternallyError';
import buyCreditsByBankTransferError from './buyCreditsByBankTransferError';
import deleteCreditCardError from './deleteCreditCardError';
import loadWalletAddressError from './loadWalletAddressError';
import confirmBankWireError from './confirmBankWireError';
import requestRevenueConversionError from './requestRevenueConversionError';
import cancelRewardRequestError from './cancelRewardRequestError';
import loadRewardSettingsError from './loadRewardSettingsError';
import sendRewardRequestError from './sendRewardRequestError';
import reportDepositIssueError from './reportDepositIssueError';
import updateAutoRefillSettingsError from './updateAutoRefillSettingsError';


export default combineReducers({
  loadDepositSettingsError,
  buyCreditsLocallyError,
  buyCreditsExternallyError,
  buyCreditsByBankTransferError,
  deleteCreditCardError,
  loadWalletAddressError,
  confirmBankWireError,
  requestRevenueConversionError,
  cancelRewardRequestError,
  loadRewardSettingsError,
  sendRewardRequestError,
  reportDepositIssueError,
  updateAutoRefillSettingsError,
});
