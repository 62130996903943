import { saleTypeLangKeys } from '../../balance/constants';


const STATISTICS_TYPE__PRIVATE = 'private';
const STATISTICS_TYPE__PUBLIC = 'public';

export const statisticsTypeLangKeys = {
  ...saleTypeLangKeys,
  [STATISTICS_TYPE__PRIVATE]: 'msg.statistics-type.private',
  [STATISTICS_TYPE__PUBLIC]: 'msg.statistics-type.public',
};

export const PAYING_MEMBERS_GRID_NAME = 'PayingMembersGrid';

const PAYING_MEMBERS_ORDER_OPTION__NICK = 'nick';
const PAYING_MEMBERS_ORDER_OPTION__FAVORITE = 'favorite';
const PAYING_MEMBERS_ORDER_OPTION__REVENUE = 'revenue';
export const PAYING_MEMBERS_ORDER_OPTION__LAST_SALE_DATE = 'lastSaleDate';

export const payingMembersOrderOptions = [
  { value: PAYING_MEMBERS_ORDER_OPTION__NICK, langKey: 'msg.nick' },
  { value: PAYING_MEMBERS_ORDER_OPTION__FAVORITE, langKey: 'msg.favorites' },
  { value: PAYING_MEMBERS_ORDER_OPTION__REVENUE, langKey: 'msg.total-spending' },
  { value: PAYING_MEMBERS_ORDER_OPTION__LAST_SALE_DATE, langKey: 'msg.recent-spending' },
];
