import React from 'react';
import PropTypes from 'prop-types';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useCurrentLang } from 'react-redux-app/lib/modules/i18n/hooks';

import { getIndexUrl } from '../../utils/url';

import { getLangToLogo } from '../../../initSettings/selectors';

import LangLink from '../../../core/i18n/components/LangLink';

import styles from './styles/index.css.json';


const propTypes = {
  isNotLink: PropTypes.bool,
  className: PropTypes.string,
};

const Logo = ({ isNotLink = false, className = null }) => {
  const lang = useCurrentLang();
  const { url, width } = useShortSelector(getLangToLogo)[lang] || {};

  const style = {};
  if (width) {
    style.width = `${width}px`;
  }

  return isNotLink ? (
    <img src={url} alt="Logo" className={className} style={style} />
  ) : (
    <LangLink to={getIndexUrl()}>
      <img src={url} alt="Logo" className={styles.logo} style={style} />
    </LangLink>
  );
};

Logo.propTypes = propTypes;

export default Logo;
