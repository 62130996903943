import { combineReducers } from 'redux';

import loadDashboardDataError from './loadDashboardDataError';
import loadPayingMembersError from './loadPayingMembersError';
import loadStatisticsError from './loadStatisticsError';
import loadUserNoteError from './loadUserNoteError';
import updateUserNoteError from './updateUserNoteError';


export default combineReducers({
  loadDashboardDataError,
  loadPayingMembersError,
  loadStatisticsError,
  loadUserNoteError,
  updateUserNoteError,
});
