import { combineReducers } from 'redux';

import { defaultItemsState } from '../../core/constants/items';

import {
  ACTION_SET_PAYING_MEMBERS,
  ACTION_SET_PAYING_MEMBER_IDS,
  ACTION_LOAD_PAYING_MEMBERS__REQUEST,
  ACTION_LOAD_PAYING_MEMBERS__SUCCESS,
  ACTION_LOAD_PAYING_MEMBERS__FAIL,
} from '../actionTypes';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_SET_PAYING_MEMBERS:
      return action.payload.payingMembers;

    default:
      return state;
  }
};

const payingMembers = (state = defaultItemsState, action) => {
  switch (action.type) {
    case ACTION_SET_PAYING_MEMBER_IDS:
      return action.payload.payingMemberIds;

    default:
      return state;
  }
};

const arePayingMembersDisabled = (state = false, action) => {
  switch (action.type) {
    case ACTION_LOAD_PAYING_MEMBERS__SUCCESS:
    case ACTION_LOAD_PAYING_MEMBERS__FAIL:
      return false;

    case ACTION_LOAD_PAYING_MEMBERS__REQUEST:
      return true;

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  payingMembers,
  arePayingMembersDisabled,
});
