import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { cookie } from 'react-redux-app/lib/modules/core/utils';

import { gatewayAnswerPropType } from '../../propTypes';

import {
  AFFILIATE_ID_COOKIE,
  AFFILIATE_ID_ACTIVATED_COOKIE,
  COOKIE_AFFILIATE_ID_ACTIVATED_EXPIRES,
} from '../../../core/api/constants';
import { GATE_ANSWER__YES } from '../../constants';

import GoogleAnalyticsPurchaseEventTracker from '../GoogleAnalyticsPurchaseEventTracker';
import TrafficJunkyTracker from '../TrafficJunkyTracker';


const propTypes = {
  answer: gatewayAnswerPropType,
  id: PropTypes.number,
  amount: PropTypes.number,
};

const DepositTracker = ({ answer = null, id = null, amount = null }) => {
  useEffect(() => {
    if (
      answer === GATE_ANSWER__YES
      && cookie.getCookie(AFFILIATE_ID_COOKIE)
      && !cookie.getCookie(AFFILIATE_ID_ACTIVATED_COOKIE)
    ) {
      cookie.setCookie(
        AFFILIATE_ID_ACTIVATED_COOKIE,
        1,
        {
          expires: COOKIE_AFFILIATE_ID_ACTIVATED_EXPIRES,
          path: '/',
        }
      );
    }
  }, [answer]);

  return answer === GATE_ANSWER__YES && id && amount && (
    <>
      <TrafficJunkyTracker depositId={id} amount={amount} />
      <GoogleAnalyticsPurchaseEventTracker depositId={id} amount={amount} />
    </>
  );
};

DepositTracker.propTypes = propTypes;

export default DepositTracker;
