import {
  ACTION_LOAD_PAYING_MEMBERS__REQUEST,
  ACTION_LOAD_PAYING_MEMBERS__SUCCESS,
  ACTION_LOAD_PAYING_MEMBERS__FAIL,
} from '../../actionTypes';


export default (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_PAYING_MEMBERS__REQUEST:
    case ACTION_LOAD_PAYING_MEMBERS__SUCCESS:
      return null;

    case ACTION_LOAD_PAYING_MEMBERS__FAIL:
      return action.payload.error;

    default:
      return state;
  }
};
