export const CATEGORY_QUERY_PARAM = 'category';

const EROSTORY_ORDER__DATE = 'date';
const EROSTORY_ORDER__VIEWS = 'views';

export const erostoryOrderOptions = [
  { value: EROSTORY_ORDER__DATE, langKey: 'msg.newest' },
  { value: EROSTORY_ORDER__VIEWS, langKey: 'msg.most-viewed' },
];

const MEDIA_ORDER__DATE = 'date';
const MEDIA_ORDER__VIEWS = 'views';
const MEDIA_ORDER__COMMENTS = 'comments';

export const mediaOrderOptions = [
  { value: MEDIA_ORDER__DATE, langKey: 'msg.newest' },
  { value: MEDIA_ORDER__VIEWS, langKey: 'msg.most-viewed' },
  { value: MEDIA_ORDER__COMMENTS, langKey: 'msg.most-commented' },
];

const UNDERWEAR_ORDER__DATE = 'date';
export const UNDERWEAR_ORDER__CHEAP = 'cheap';
export const UNDERWEAR_ORDER__EXPENSIVE = 'expensive';

export const underwearOrderOptions = [
  { value: UNDERWEAR_ORDER__DATE, langKey: 'msg.newest' },
  { value: UNDERWEAR_ORDER__CHEAP, langKey: 'msg.cheapest' },
  { value: UNDERWEAR_ORDER__EXPENSIVE, langKey: 'msg.most-expensive' },
];
