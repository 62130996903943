export const MODAL_DIALOG__CREATE_COVER_PHOTO = 'MODAL_DIALOG__CREATE_COVER_PHOTO';
export const MODAL_DIALOG__CREATE_EROSTORY = 'MODAL_DIALOG__CREATE_EROSTORY';
export const MODAL_DIALOG__CREATE_PRESENTATION = 'MODAL_DIALOG__CREATE_PRESENTATION';
export const MODAL_DIALOG__CREATE_PRIVATE_PHOTO = 'MODAL_DIALOG__CREATE_PRIVATE_PHOTO';
export const MODAL_DIALOG__CREATE_PROFILE_PHOTO = 'MODAL_DIALOG__CREATE_PROFILE_PHOTO';
export const MODAL_DIALOG__CREATE_PUBLIC_PHOTO = 'MODAL_DIALOG__CREATE_PUBLIC_PHOTO';
export const MODAL_DIALOG__CREATE_UNDERWEAR = 'MODAL_DIALOG__CREATE_UNDERWEAR';
export const MODAL_DIALOG__CREATE_VIDEO = 'MODAL_DIALOG__CREATE_VIDEO';
export const MODAL_DIALOG__HELP = 'MODAL_DIALOG__HELP';
export const MODAL_DIALOG__POST_RECORDED_PRIVATE = 'MODAL_DIALOG__POST_RECORDED_PRIVATE';
export const MODAL_DIALOG__UPDATE_EROSTORY = 'MODAL_DIALOG__UPDATE_EROSTORY';
export const MODAL_DIALOG__UPDATE_PERSONAL_CONTACT = 'MODAL_DIALOG__UPDATE_PERSONAL_CONTACT';
export const MODAL_DIALOG__UPDATE_PRESENTATION = 'MODAL_DIALOG__UPDATE_PRESENTATION';
export const MODAL_DIALOG__UPDATE_PRIVATE_PHOTO = 'MODAL_DIALOG__UPDATE_PRIVATE_PHOTO';
export const MODAL_DIALOG__UPDATE_PROFILE_PHOTO = 'MODAL_DIALOG__UPDATE_PROFILE_PHOTO';
export const MODAL_DIALOG__UPDATE_PUBLIC_PHOTO = 'MODAL_DIALOG__UPDATE_PUBLIC_PHOTO';
export const MODAL_DIALOG__UPDATE_UNDERWEAR = 'MODAL_DIALOG__UPDATE_UNDERWEAR';
export const MODAL_DIALOG__UPDATE_VIDEO = 'MODAL_DIALOG__UPDATE_VIDEO';
