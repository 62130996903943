import { MODAL_DIALOG__USER_REGISTRATION } from '../../constants/dialogs';

import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';


export const actionShowUserRegistrationDialog = welcomeBonus => dispatch => (
  dispatch(actionShowModal(MODAL_DIALOG__USER_REGISTRATION, { welcomeBonus }))
);

export const actionHideUserRegistrationDialog = () => dispatch => (
  dispatch(actionHideModal(MODAL_DIALOG__USER_REGISTRATION))
);
