import _get from 'lodash/get';


const errorsSelector = state => _get(state, 'statistics.errors');
export const getLoadDashboardDataError = state => (
  _get(errorsSelector(state), 'loadDashboardDataError')
);
export const getLoadStatisticsError = state => (
  _get(errorsSelector(state), 'loadStatisticsError')
);
export const getLoadPayingMembersError = state => (
  _get(errorsSelector(state), 'loadPayingMembersError')
);
export const getLoadUserNoteError = state => (
  _get(errorsSelector(state), 'loadUserNoteError')
);
export const getUpdateUserNoteError = state => (
  _get(errorsSelector(state), 'updateUserNoteError')
);
