import {
  currencyToSignMap,
  CURRENCY__EUR,
  CURRENCY__USD,
  CURRENCY__GBP,
  CURRENCY__RUB,
  CURRENCY__CHF,
  CURRENCY__CRD,
} from '../constants/currency';

import { formatNumber } from 'react-redux-app/lib/modules/i18n/utils';


/**
 * format money to string like as "2 000.00 €", "200.50 €"
 * @param {Object} money
 * @param {Number} money.amount float value.
 * @param {String} money.currency (EUR, USD, CREDITS...)
 * @return {String}
 */

const formatStraight = (amount, currency) => (
  `${formatNumber(amount, 2)} ${currencyToSignMap[currency]}`
);

const formatReverse = (amount, currency) => (
  `${currencyToSignMap[currency]} ${formatNumber(amount, 2)}`
);

const moneyFormatterMap = {
  [CURRENCY__EUR]: formatStraight,
  [CURRENCY__USD]: formatReverse,
  [CURRENCY__GBP]: formatReverse,
  [CURRENCY__RUB]: formatStraight,
  [CURRENCY__CHF]: formatStraight,
  [CURRENCY__CRD]: (amount, currency) => `${amount} ${currencyToSignMap[currency]}`,
};

export default (amount, currency = CURRENCY__CRD) => moneyFormatterMap[currency](amount, currency);
