export const CATEGORY__JOIN = 'join';
export const CATEGORY__PRODUCTS = 'products';
export const CATEGORY__INFORMATION = 'information';
export const CATEGORY__HELP = 'help';
export const CATEGORY__OTHER = 'other';

export const defaultCategories = [
  CATEGORY__JOIN,
  CATEGORY__PRODUCTS,
  CATEGORY__INFORMATION,
  CATEGORY__HELP,
  CATEGORY__OTHER,
];

export const defaultCategoryLangKeys = {
  [CATEGORY__JOIN]: 'msg.category.join',
  [CATEGORY__PRODUCTS]: 'msg.category.products',
  [CATEGORY__INFORMATION]: 'msg.category.information',
  [CATEGORY__HELP]: 'msg.category.support',
  [CATEGORY__OTHER]: 'msg.category.other',
};

export const ARTICLE_VISIBILITY__ALL = 'all';
export const ARTICLE_VISIBILITY__GUEST = 'guest';
export const ARTICLE_VISIBILITY__FEMALE = 'female';
export const ARTICLE_VISIBILITY__MALE = 'male';
