import { combineReducers } from 'redux';
import _omit from 'lodash/omit';

import errors from './errors';

import {
  ACTION_SET_DIALOGS,
  ACTION_SET_DIALOG_IDS,
  ACTION_DELETE_DIALOG__REQUEST,
  ACTION_DELETE_DIALOG__SUCCESS,
  ACTION_DELETE_DIALOG__FAIL,
  ACTION_DIALOGS_MOUNTED,
  ACTION_DIALOGS_UNMOUNTED,
  ACTION_ENABLE_DIALOGS,
  ACTION_DISABLE_DIALOGS,
} from '../actionTypes';

import { defaultItemsState } from '../../core/constants/items';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_SET_DIALOGS:
      return action.payload.dialogs;

    default:
      return state;
  }
};

const dialogs = (state = defaultItemsState, action) => {
  switch (action.type) {
    case ACTION_SET_DIALOG_IDS:
      return action.payload.dialogIds;

    default:
      return state;
  }
};

const isDialogBeingDeleted = (state = {}, action) => {
  switch (action.type) {
    case ACTION_DELETE_DIALOG__REQUEST:
      return { ...state, [action.payload.peerId]: true };

    case ACTION_DELETE_DIALOG__SUCCESS:
    case ACTION_DELETE_DIALOG__FAIL:
      return _omit(state, action.payload.peerId);

    default:
      return state;
  }
};

const areDialogsMounted = (state = null, action) => {
  switch (action.type) {
    case ACTION_DIALOGS_MOUNTED:
      return true;

    case ACTION_DIALOGS_UNMOUNTED:
      return false;

    default:
      return state;
  }
};

const areDialogsDisabled = (state = false, action) => {
  switch (action.type) {
    case ACTION_ENABLE_DIALOGS:
      return false;

    case ACTION_DISABLE_DIALOGS:
      return true;

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  dialogs,
  isDialogBeingDeleted,
  areDialogsMounted,
  areDialogsDisabled,
  errors,
});
