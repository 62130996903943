export const WEB_SOCKET__BALANCE_AUTO_REFILL = 'balanceAutoRefill';
export const WEB_SOCKET__BALANCE_CHANGED = 'balanceChanged';
export const WEB_SOCKET__FAVORITE_STATUS_CHANGED = 'favoriteStatusChanged';
export const WEB_SOCKET__GIFT_RECEIVED = 'giftReceived';
export const WEB_SOCKET__ONLINE_FAVORITES_NUMBER_CHANGED = 'onlineFavoritesNumberChanged';
export const WEB_SOCKET__PRIVATE_MESSAGE_RECEIVED = 'privateMessageReceived';
export const WEB_SOCKET__REAL_GIFT_RECEIVED = 'realGiftReceived';
export const WEB_SOCKET__SITE_BAN_DROPPED = 'siteBanDropped';
export const WEB_SOCKET__SITE_BAN_SET = 'siteBanSet';
export const WEB_SOCKET__UNREAD_FEED_COUNT_CHANGED = 'unreadFeedCountChanged';
export const WEB_SOCKET__UNREAD_MAIL_COUNT_CHANGED = 'unreadMailCountChanged';
