import { ACTION_SIGNAL_ROOT_MOUNTED } from '../actionTypes/signalRootMounted';


export default (state = false, action) => {
  switch (action.type) {
    case ACTION_SIGNAL_ROOT_MOUNTED:
      return true;

    default:
      return state;
  }
};
