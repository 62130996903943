import PropTypes from 'prop-types';


export const menuItemsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string,
    langKey: PropTypes.string,
    path: PropTypes.string.isRequired,
  })
);
