import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useIntl } from 'react-redux-app/lib/modules/i18n/hooks';
import {
  useIsAgency,
  useIsCommon,
  useIsModel,
  useUserCredits,
} from '../../../auth/hooks';
import { useIsRivcam, useIsVideoSite } from '../../../initSettings/hooks';
import { useNavigateToPublicRoomOverlay } from '../../hooks';

import { getChatCreditsThreshold } from '../../../initSettings/selectors';
import { getShowAdultWarning } from '../../selectors/adultWarning';

import { actionGetCredits } from '../../../deposit/actions/getCredits';
import { actionShowAdultWarningDialog } from '../../actions/dialogs/adultWarningDialog';

import styles from './styles/index.css.json';


const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string.isRequired,
};

const VideochatLink = ({ children = null, className }) => {
  const dispatch = useDispatch();

  const chatCreditsThreshold = useShortSelector(getChatCreditsThreshold);
  const showAdultWarning = useSelector(state => getShowAdultWarning(state));
  const isAgency = useIsAgency();
  const isCommon = useIsCommon();
  const isModel = useIsModel();
  const credits = useUserCredits();
  const isRivcam = useIsRivcam();
  const isVideoSite = useIsVideoSite();

  const { formatMessage: fm } = useIntl();

  const navigateToPublicRoomOverlay = useNavigateToPublicRoomOverlay();

  let action;
  if (showAdultWarning) {
    action = actionShowAdultWarningDialog();
  } else if (isCommon && credits < chatCreditsThreshold) {
    action = actionGetCredits({
      message: fm(
        { id: 'error.credits-required-for-videochat' }, { credits: chatCreditsThreshold }
      ),
    });
  }

  return !isVideoSite && !isAgency && (!isRivcam || isModel) && (
    <div
      onClick={() => {
        if (action) {
          dispatch(action);
        } else {
          navigateToPublicRoomOverlay();
        }
      }}
      className={classnames(styles.videochat, className)}
    >
      {children}
    </div>
  );
};

VideochatLink.propTypes = propTypes;

export default VideochatLink;
