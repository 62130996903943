import { createSelector } from 'reselect';
import _get from 'lodash/get';

import { getOwnSaleItems } from './index';


const presentationSelector = state => _get(state, 'ownSaleItem.presentation');

export const getPresentationIds = state => _get(presentationSelector(state), 'presentations');

export const getActivePresentation = createSelector(
  getOwnSaleItems,
  getPresentationIds,
  (ownSaleItems, presentationIds) => (
    ownSaleItems[presentationIds.filter(id => ownSaleItems[id].isActive)?.[0]]
  )
);

export const getIsPresentationBeingDeleted = (state, presentationId) => (
  !!_get(presentationSelector(state), `isPresentationBeingDeleted.${presentationId}`)
);

const errorsSelector = state => _get(presentationSelector(state), 'errors');
export const getLoadPresentationsError = state => (
  _get(errorsSelector(state), 'loadPresentationsError')
);
export const getActivatePresentationError = state => (
  _get(errorsSelector(state), 'activatePresentationError')
);
export const getCreatePresentationError = state => (
  _get(errorsSelector(state), 'createPresentationError')
);
export const getDeletePresentationError = state => (
  _get(errorsSelector(state), 'deletePresentationError')
);
export const getUpdatePresentationError = state => (
  _get(errorsSelector(state), 'updatePresentationError')
);
