import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import LangLink from '../../../core/i18n/components/LangLink';

import { theming } from 'react-redux-app/lib/modules/core/utils';
import { MENU_LINK } from '../../constants/theme';
import baseCssJson from './styles/base/index.css.json';


export const themeApi = [
  'menuLink',
  'menuLink_active',
];

const propTypes = {
  className: PropTypes.string,

  theme: theming.getThemePropTypesShape(themeApi).isRequired,
};

const MenuLink = ({ className = null, theme, ...rest }) => (
  <LangLink
    className={classnames(theme.menuLink, className)}
    activeClassName={theme.menuLink_active}
    {...rest}
  />
);

MenuLink.propTypes = propTypes;

export default theming.theme(
  MENU_LINK,
  baseCssJson,
  themeApi
)(MenuLink);
