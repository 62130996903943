import { createSelector } from 'reselect';
import _get from 'lodash/get';

import { REAL_GIFT_STATUS__CREATED } from '../constants';


const getGifts = state => _get(state, 'gift.byId');
const getGift = (state, id) => _get(getGifts(state), id);
export const getGiftImages = (state, id) => _get(getGift(state, id), 'images');

export const getRealGiftOffers = state => _get(state, 'gift.realGiftOffersById');
export const getIsActionable = (state, id) => (
  _get(getRealGiftOffers(state), `${id}.status`) === REAL_GIFT_STATUS__CREATED
);

export const getAvailableGiftIds = state => _get(state, 'gift.order');
export const getAvailableGifts = createSelector(
  getAvailableGiftIds,
  getGifts,
  (availableGiftIds, gifts) => availableGiftIds.map(id => _get(gifts, id))
);

export const getAnonymityPrice = state => _get(state, 'gift.settings.anonymityPrice');

export const getSelectedGiftId = state => _get(state, 'gift.selected');
export const getSelectedGift = state => getGift(state, getSelectedGiftId(state));

export const getIsSendingConfirmed = state => _get(state, 'gift.isSendingConfirmed');

export const getRealGiftDeliveryAddress = state => _get(state, 'gift.realGiftDeliveryAddress');

export const getIsRealGiftBeingRejected = state => !!_get(state, 'gift.isRealGiftBeingRejected');
