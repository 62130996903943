export const ACTION_LOAD_STATISTICS__REQUEST = 'ACTION_LOAD_STATISTICS__REQUEST';
export const ACTION_LOAD_STATISTICS__SUCCESS = 'ACTION_LOAD_STATISTICS__SUCCESS';
export const ACTION_LOAD_STATISTICS__FAIL = 'ACTION_LOAD_STATISTICS__FAIL';

export const ACTION_LOAD_DASHBOARD_DATA__REQUEST = 'ACTION_LOAD_DASHBOARD_DATA__REQUEST';
export const ACTION_LOAD_DASHBOARD_DATA__SUCCESS = 'ACTION_LOAD_DASHBOARD_DATA__SUCCESS';
export const ACTION_LOAD_DASHBOARD_DATA__FAIL = 'ACTION_LOAD_DASHBOARD_DATA__FAIL';

export const ACTION_LOAD_PAYING_MEMBERS__REQUEST = 'ACTION_LOAD_PAYING_MEMBERS__REQUEST';
export const ACTION_LOAD_PAYING_MEMBERS__SUCCESS = 'ACTION_LOAD_PAYING_MEMBERS__SUCCESS';
export const ACTION_LOAD_PAYING_MEMBERS__FAIL = 'ACTION_LOAD_PAYING_MEMBERS__FAIL';
export const ACTION_SET_PAYING_MEMBERS = 'ACTION_SET_PAYING_MEMBERS';
export const ACTION_SET_PAYING_MEMBER_IDS = 'ACTION_SET_PAYING_MEMBER_IDS';

export const ACTION_LOAD_USER_NOTE__REQUEST = 'ACTION_LOAD_USER_NOTE__REQUEST';
export const ACTION_LOAD_USER_NOTE__SUCCESS = 'ACTION_LOAD_USER_NOTE__SUCCESS';
export const ACTION_LOAD_USER_NOTE__FAIL = 'ACTION_LOAD_USER_NOTE__FAIL';

export const ACTION_UPDATE_USER_NOTE__REQUEST = 'ACTION_UPDATE_USER_NOTE__REQUEST';
export const ACTION_UPDATE_USER_NOTE__SUCCESS = 'ACTION_UPDATE_USER_NOTE__SUCCESS';
export const ACTION_UPDATE_USER_NOTE__FAIL = 'ACTION_UPDATE_USER_NOTE__FAIL';
