// import { normalize } from 'normalizr';

import { url } from 'react-redux-app/lib/modules/routing/utils';
import {
  getIndexUrl,
  getRequestsUrl,
  getMakeFirstDepositUrl,
  getMakeNewDepositUrl,
  getSignupUrl,
  getPayoutRequestNoticeUrl,
  getRevenueUrl,
} from '../../app/utils/url';
import { modifyHelpCamonLiveUrl } from '../../initSettings/utils';

import {
  REQUESTS_TAB__APPOINTMENTS,
  REQUESTS_TAB__INTERVIEWS,
  REQUESTS_TAB__REAL_GIFTS,
  REQUESTS_TAB__UNDERWEAR,
} from '../../app/constants/tabs';
import { WEEKEND_ID, CRYPTO_ID } from '../../chatGallery/constants';
import {
  PRIZE_TYPE__EMOTICON_DISCOUNT,
  PRIZE_TYPE__EMOTICON_2_DISCOUNT,
} from '../../prize/constants';
import { REVENUE_TAB__LATEST_SALES } from '../../revenue/constants/tabs';
import {
  USER_TYPE__AGENCY,
  USER_TYPE__MODEL,
  RIV_OLD_VERSION_COOKIE,
  COOKIE_RIV_OLD_VERSION_EXPIRES,
  EXPIRED_QUERY_PARAM,
  REFERRER_QUERY_PARAM,
} from '../constants';

// import { GIFTS_SCHEMA } from '../../gift/schemas';

import { getCurrentLang } from 'react-redux-app/lib/modules/i18n/selectors';
import { getCurrentLangForUrl } from '../../core/i18n/selectors';
import { getCreditsBonus } from '../../chatGallery/selectors/models';

import { actionShowWeekendBonusDialog } from '../../chatGallery/actions/dialogs/weekendBonusDialog';
import { actionShowCryptoBonusDialog } from '../../chatGallery/actions/dialogs/cryptoBonusDialog';
// import { actionLoadGiftsSuccess } from '../../gift/actions/loadGifts';
// import { actionShowSendGiftDialog } from '../../gift/actions/dialogs/sendGiftDialog';
import { actionShowAdventPrizesDialog } from '../actions/dialogs/adventPrizesDialog';
import { actionShowFillNameDialog } from '../actions/dialogs/fillNameDialog';
import { actionGetCryptoBonusCredits } from '../actions/getCryptoBonusCredits';
import { actionShowGiftEventDialog } from '../actions/dialogs/giftEventDialog';
import { actionShowMissedBonusesDialog } from '../actions/dialogs/missedBonusesDialog';


export const getExpiredRedirectUrl = (langForUrl, currentLocation) => (
  url.appendQueryParams(
    getIndexUrl(langForUrl),
    {
      [EXPIRED_QUERY_PARAM]: 1,
      [REFERRER_QUERY_PARAM]: currentLocation,
    }
  )
);

export const getNotLoggedRedirectUrl = (langForUrl, currentLocation) => (
  url.appendQueryParams(
    getIndexUrl(langForUrl),
    {
      [REFERRER_QUERY_PARAM]: currentLocation,
    }
  )
);

export const getRedirectUrl = (getState, user, loginInfo, redirectUrl) => {
  const langForUrl = getCurrentLangForUrl(getState());

  if (loginInfo.zendeskLoginLink) {
    return modifyHelpCamonLiveUrl(loginInfo.zendeskLoginLink, getCurrentLang(getState()));
  }
  if (loginInfo.oldUiRedirectUrl) {
    return loginInfo.oldUiRedirectUrl;
  }
  if (user.incompleteRegistration) {
    return getSignupUrl(langForUrl);
  }
  if (loginInfo.payoutRequestNotice) {
    return getPayoutRequestNoticeUrl(langForUrl);
  }
  if (loginInfo.depositRemind.needFirstDeposit) {
    return getMakeFirstDepositUrl(langForUrl);
  }
  if (loginInfo.depositRemind.needNewDeposit) {
    return getMakeNewDepositUrl(langForUrl);
  }
  if (user.requests && (
    user.requests.appointments
    + user.requests.interviews
    + user.requests.underwear > 0
  )) {
    let tab;
    if (user.requests.realGifts > 0) {
      tab = REQUESTS_TAB__REAL_GIFTS;
    } else if (user.requests.appointments > 0) {
      tab = REQUESTS_TAB__APPOINTMENTS;
    } else if (user.requests.interviews > 0) {
      tab = REQUESTS_TAB__INTERVIEWS;
    } else if (user.requests.underwear > 0) {
      tab = REQUESTS_TAB__UNDERWEAR;
    }
    return getRequestsUrl(langForUrl, tab);
  }
  if (!redirectUrl) {
    if (user.type === USER_TYPE__MODEL) {
      return getRevenueUrl(langForUrl, REVENUE_TAB__LATEST_SALES);
    }
    if (user.type === USER_TYPE__AGENCY) {
      return getIndexUrl(langForUrl);
    }
  }
  return redirectUrl;
};

export const setOldUiCookie = (setCookie, loginInfo) => {
  if (loginInfo.oldUiRedirectUrl) {
    setCookie(
      RIV_OLD_VERSION_COOKIE,
      1,
      {
        expires: COOKIE_RIV_OLD_VERSION_EXPIRES,
        path: '/',
      }
    );
  }
};

export const getIsZendesk = redirectUrl => redirectUrl === 'zendesk';

export const showDialogs = (
  dispatch, getState, loginInfo = {}, adventPrizesAvailableAfterDeposit = null
) => {
  if (loginInfo.needFillName) {
    dispatch(actionShowFillNameDialog());
  }

  const bonuses = [];
  if (loginInfo.isWeeklyEventPrizeMissed) {
    bonuses.push(WEEKEND_ID);
  }
  if (loginInfo.isCryptoEventPrizeMissed) {
    bonuses.push(CRYPTO_ID);
  }
  if (bonuses.length > 0) {
    dispatch(actionShowMissedBonusesDialog(bonuses));
  }

  if (getCreditsBonus(getState(), WEEKEND_ID)) {
    dispatch(actionShowWeekendBonusDialog());
  }

  if (getCreditsBonus(getState(), CRYPTO_ID)) {
    dispatch(actionShowCryptoBonusDialog());
  }

  if (adventPrizesAvailableAfterDeposit) {
    dispatch(actionShowAdventPrizesDialog(adventPrizesAvailableAfterDeposit));
  }

  /*
  if (loginInfo.lastPrivateWith) {
    const { result: { gifts: order, anonymityPrice }, entities } = normalize(
      loginInfo.realGifts, GIFTS_SCHEMA
    );
    dispatch(actionLoadGiftsSuccess(entities.items, order, anonymityPrice));
    dispatch(actionShowSendGiftDialog(
      loginInfo.lastPrivateWith.id, loginInfo.lastPrivateWith.nick, true
    ));
  }
  */

  if (loginInfo.activePopups?.length > 0) {
    loginInfo.activePopups.forEach(({ src, link }) => {
      if (link !== '#survey' || loginInfo.survey) {
        dispatch(actionShowGiftEventDialog(src, link, false, loginInfo.survey));
      }
    });
  }

  dispatch(actionGetCryptoBonusCredits());
};

export const convertPrizes = prizes => {
  if (!prizes) {
    return [];
  }

  const result = [...prizes];

  if (
    prizes[0].type === PRIZE_TYPE__EMOTICON_DISCOUNT
    && prizes[1]?.type === PRIZE_TYPE__EMOTICON_DISCOUNT
  ) {
    result.splice(0, 2, {
      type: PRIZE_TYPE__EMOTICON_2_DISCOUNT,
      amount: prizes[0].amount,
    });
  }

  return result;
};
