import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import { getLoadPartialInitSettingsError } from '../../../initSettings/selectors/errors';
import { getLoadModelProfileError } from '../../selectors/errors';

import { actionLoadModelProfileSettingsRequest } from '../../../initSettings/actions/loadPartialInitSettings';
import { actionLoadModelProfileRequest } from '../../actions/loadModelProfile';


export default ({ getState, dispatch }) => (
  function loadModelProfileHandler(nextState, successCallback, errorCallback) {
    Promise.all([
      dispatch(actionLoadModelProfileSettingsRequest()),
      dispatch(actionLoadModelProfileRequest()),
    ]).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        // eslint-disable-next-line no-restricted-syntax, no-unreachable-loop
        for (const { globalError } of [
          getLoadPartialInitSettingsError(getState()),
          getLoadModelProfileError(getState()),
        ].filter(error => error)) {
          if (!maybeHandleCriticalError(nextState.location, globalError, errorCallback)) {
            console.consoleError('loadModelProfileHandler error', globalError);
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadModelProfileHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
