import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import loadable from '@loadable/component';

import { useDidUpdateEffect, useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useCallCancellablePromiseAndHandleErrorOrExecute } from '../../../core/api/hooks';

import {
  getMediaViewerCloseUrl,
  getMediaViewerItemUrl,
  getMediaViewerFilter,
  getActionLoadMediaViewerItemsRequest,
  getActionLoadMediaViewerItemInfoRequest,
} from '../../utils';

import { mediaViewerBlogTypes } from '../../constants';

import { getMediaIds } from '../../selectors';
import { getItemInfo } from '../../selectors/mediaInfo';
import {
  getLoadMediaViewerItemsError,
  getLoadMediaViewerItemInfoError,
} from '../../selectors/errors';

import { actionClearMediaViewerItems } from '../../actions/loadMediaViewerItems';


const MediaViewer = loadable(() => import('react-redux-app/lib/modules/mediaViewer/components/MediaViewer'));
const Media = loadable(() => import('../Media'));
const MediaViewerControls = loadable(() => import('../MediaViewerControls'));

export default () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const mediaIds = useShortSelector(getMediaIds);
  const itemInfo = useShortSelector(getItemInfo);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const mediaViewerFilter = useMemo(() => getMediaViewerFilter(location), [location.search]);
  const type = mediaViewerFilter?.type;
  const album = mediaViewerFilter?.album;
  const id = mediaViewerFilter?.id;

  const isBlog = mediaViewerBlogTypes.includes(type);

  const items = useMemo(() => type && mediaIds?.map(mediaId => ({
    contentComponentProps: { id: mediaId, isBlog },
    pageUrl: getMediaViewerItemUrl(location, type, album, mediaId),
  })), [type, mediaIds]); // eslint-disable-line react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const closeUrl = useMemo(() => type && getMediaViewerCloseUrl(location), [type]);

  const closeMediaViewer = () => { navigate(closeUrl); };

  const callActionLoadMediaViewerItemsRequest = (
    useCallCancellablePromiseAndHandleErrorOrExecute(getLoadMediaViewerItemsError)
  );

  useDidUpdateEffect(() => {
    if (type) {
      callActionLoadMediaViewerItemsRequest(
        dispatch(getActionLoadMediaViewerItemsRequest(location)()),
        'header.media-viewer-items.load',
        null,
        closeMediaViewer,
        false
      );
    } else {
      dispatch(actionClearMediaViewerItems());
    }
  }, [type]);

  const callActionLoadMediaViewerItemInfoRequest = (
    useCallCancellablePromiseAndHandleErrorOrExecute(getLoadMediaViewerItemInfoError)
  );

  useDidUpdateEffect(() => {
    if (type) {
      const actionLoadRequest = getActionLoadMediaViewerItemInfoRequest(location);
      if (actionLoadRequest) {
        callActionLoadMediaViewerItemInfoRequest(
          dispatch(actionLoadRequest()),
          'header.media-viewer-item-info.load',
          null,
          closeMediaViewer,
          false
        );
      }
    }
  }, [id]);

  return type && items && itemInfo && (
    <MediaViewer
      navigate={navigate}
      closeUrl={closeUrl}
      currentItemPosition={Math.max(0, mediaIds.indexOf(parseInt(id, 10)))}
      defaultContentComponent={Media}
      mediaViewerControlsComponent={MediaViewerControls}
      items={items}
      isWithSmallNavigation={isBlog}
    />
  );
};
