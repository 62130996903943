import {
  ACTION_LOAD_STATISTICS__REQUEST,
  ACTION_LOAD_STATISTICS__SUCCESS,
  ACTION_LOAD_STATISTICS__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionLoadStatisticsSuccess = statistics => ({
  type: ACTION_LOAD_STATISTICS__SUCCESS,
  payload: { statistics },
});

const actionLoadStatisticsFail = error => ({
  type: ACTION_LOAD_STATISTICS__FAIL,
  payload: { error },
});

export const actionLoadStatisticsRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_STATISTICS__REQUEST,
    });

    return apiClientHelper.get(
      'modelhub/stats-overview'
    ).then(
      result => {
        dispatch(actionLoadStatisticsSuccess(result));
      },
      error => {
        dispatch(actionLoadStatisticsFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
