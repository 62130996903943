import { showDialogs } from '../utils';

import { getLoginInfo } from '../selectors';


export const actionShowDialogs = () => (
  (dispatch, getState) => {
    showDialogs(dispatch, getState, getLoginInfo(getState()));
  }
);
