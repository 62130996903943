import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import useCurrentLangForUrl from '../../../core/i18n/hooks/useCurrentLangForUrl';
// import useShowSendGiftDialog from '../../../gift/hooks/useShowSendGiftDialog';
import { useIsMobileDevice } from '../../../initSettings/hooks';

import { dom } from 'react-redux-app/lib/modules/core/utils';
import { getEventUrl, getSugarBabyUrl } from '../../utils/url';

import { getEventBanner } from '../../../initSettings/selectors';

// import LangLink from '../../../core/i18n/components/LangLink';

import styles from './styles/index.css.json';


const MIN_BANNER_WIDTH = 1400;

export default () => {
  // const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { src, link } = useShortSelector(getEventBanner) || {};
  const langForUrl = useCurrentLangForUrl();
  const isMobileDevice = useIsMobileDevice();

  const banner = useRef();
  const iframe = useRef();

  // const showSendGiftDialog = useShowSendGiftDialog(true);

  const showBanner = src && pathname !== getEventUrl(langForUrl);

  const onResize = () => {
    const bannerWidth = banner.current.getBoundingClientRect().width;
    if (bannerWidth >= MIN_BANNER_WIDTH) {
      iframe.current.style.transform = '';
      banner.current.style.height = '';
    } else {
      const scale = Math.max(0.5, bannerWidth / MIN_BANNER_WIDTH);
      iframe.current.style.transform = `scale(${scale})`;
      banner.current.style.height = `${90 * scale}px`;
    }
  };

  useEffect(() => {
    if (isMobileDevice || !showBanner) {
      return undefined;
    }

    onResize();

    window.addEventListener('resize', onResize);

    return () => { window.removeEventListener('resize', onResize); };
  }, [isMobileDevice, showBanner]);

  return showBanner && (
    <div
      ref={banner}
      onClick={() => {
        // showSendGiftDialog();
        dom.redirect(link);
      }}
      className={styles.banner}
    >
      {/* <LangLink to={getSugarBabyUrl()} /> */}
      <iframe
        ref={iframe}
        title="Top Model"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        scrolling="no"
        src={src}
        width={isMobileDevice ? '100%' : ''}
        height={isMobileDevice ? 90 : ''}
        className={classnames(styles.iFrame, { [styles.iFrame_desktop]: !isMobileDevice })}
      />
      {/* </LangLink> */}
    </div>
  );
};
