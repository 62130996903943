import { combineReducers } from 'redux';

import loadAccountError from './loadAccountError';
import updateAccountError from './updateAccountError';
import deleteAccountError from './deleteAccountError';
import updateEmailError from './updateEmailError';
import confirmEmailError from './confirmEmailError';
import optoutEmailError from './optoutEmailError';
import resetEmailError from './resetEmailError';

import loadPreferencesError from './loadPreferencesError';
import updatePreferencesError from './updatePreferencesError';

import loadWebcamSettingsError from './loadWebcamSettingsError';
import updateWebcamSettingsError from './updateWebcamSettingsError';

import loadBlueCheckSettingsError from './loadBlueCheckSettingsError';
import updateBlueCheckSettingsError from './updateBlueCheckSettingsError';
import updateBlueCheckAutoConvertError from './updateBlueCheckAutoConvertError';

import loadVideochatExternalApplicationSettingsError from './loadVideochatExternalApplicationSettingsError';
import generateVideochatExternalApplicationSettingsError from './generateVideochatExternalApplicationSettingsError';
import sendSpeedTestResultError from './sendSpeedTestResultError';

import loadPrivacyError from './loadPrivacyError';
import updatePrivacyError from './updatePrivacyError';

import loadPrivacyAddressInformationError from './loadPrivacyAddressInformationError';
import updatePrivacyAddressInformationError from './updatePrivacyAddressInformationError';

import addGeoFilterError from './addGeoFilterError';
import deleteGeoFilterError from './deleteGeoFilterError';

import loadModelProfileError from './loadModelProfileError';
import updateModelProfileError from './updateModelProfileError';

import loadTelegramSettingsError from './loadTelegramSettingsError';
import connectToTelegramError from './connectToTelegramError';
import updateTelegramNotificationsError from './updateTelegramNotificationsError';
import disconnectFromTelegramError from './disconnectFromTelegramError';

import loadPushSettingsError from './loadPushSettingsError';
import updatePushSettingsError from './updatePushSettingsError';

import loadSocialSettingsError from './loadSocialSettingsError';
import attachSocialError from './attachSocialError';
import completeSocialAttachmentError from './completeSocialAttachmentError';
import detachSocialError from './detachSocialError';

import addWhiteListedUserError from './addWhiteListedUserError';
import deleteWhiteListedUserError from './deleteWhiteListedUserError';

import loadBlogSettingsError from './loadBlogSettingsError';
import updateBlogSettingsError from './updateBlogSettingsError';

import loadFlowerDeliverySettingsError from './loadFlowerDeliverySettingsError';
import updateFlowerDeliverySettingsError from './updateFlowerDeliverySettingsError';


export default combineReducers({
  loadAccountError,
  updateAccountError,
  deleteAccountError,
  updateEmailError,
  confirmEmailError,
  optoutEmailError,
  resetEmailError,
  loadPreferencesError,
  updatePreferencesError,
  loadWebcamSettingsError,
  updateWebcamSettingsError,
  loadBlueCheckSettingsError,
  updateBlueCheckSettingsError,
  updateBlueCheckAutoConvertError,
  loadVideochatExternalApplicationSettingsError,
  generateVideochatExternalApplicationSettingsError,
  sendSpeedTestResultError,
  loadPrivacyError,
  updatePrivacyError,
  loadPrivacyAddressInformationError,
  updatePrivacyAddressInformationError,
  addGeoFilterError,
  deleteGeoFilterError,
  loadModelProfileError,
  updateModelProfileError,
  loadTelegramSettingsError,
  connectToTelegramError,
  updateTelegramNotificationsError,
  disconnectFromTelegramError,
  loadPushSettingsError,
  updatePushSettingsError,
  loadSocialSettingsError,
  attachSocialError,
  completeSocialAttachmentError,
  detachSocialError,
  addWhiteListedUserError,
  deleteWhiteListedUserError,
  loadBlogSettingsError,
  updateBlogSettingsError,
  loadFlowerDeliverySettingsError,
  updateFlowerDeliverySettingsError,
});
