import _omit from 'lodash/omit';
import _pick from 'lodash/pick';

import { dom, removeBlankFields } from 'react-redux-app/lib/modules/core/utils';
import { url } from 'react-redux-app/lib/modules/routing/utils';
import preloadImage from '../../core/utils/preloadImage';
import { setModelOrder } from '../utils';

import {
  ACTION_LOAD_MODELS__REQUEST,
  ACTION_LOAD_MODELS__SUCCESS,
  ACTION_LOAD_MODELS__FAIL,

  ACTION_SET_MODELS_PRESERVE,
  ACTION_ADD_MODEL_IDS,
  ACTION_SET_MODEL_IDS,
} from '../actionTypes';

import { PAGINATION_MODE__SCROLL } from '../../core/loadableItems/constants';
import { chatGalleryQueryParams } from '../constants';

import { ITEMS_SCHEMA } from '../../core/schemas/items';

import { getModelLimit, getModelPaginationMode } from '../selectors/models';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import {
  actionAddModels,
  actionSetModelChatStatuses,
  actionSetAllowedTypes,
  getModelsAndStatuses,
} from './models';


const actionAddModelIds = models => ({
  type: ACTION_ADD_MODEL_IDS,
  payload: { models },
});

const actionSetModelIds = models => ({
  type: ACTION_SET_MODEL_IDS,
  payload: { models },
});

export const actionSetModelsPreserve = () => ({
  type: ACTION_SET_MODELS_PRESERVE,
});

const actionLoadModelsSuccess = () => ({
  type: ACTION_LOAD_MODELS__SUCCESS,
});

const actionLoadModelsFail = error => ({
  type: ACTION_LOAD_MODELS__FAIL,
  payload: { error },
});

export const actionLoadModelsRequest = (params, isHomePage = false) => (
  (dispatch, getState, { apiClientHelper, getCookie }) => {
    const preserve = getModelPaginationMode(getState()) === PAGINATION_MODE__SCROLL;

    dispatch({
      type: ACTION_LOAD_MODELS__REQUEST,
    });

    const setAllowedTypes = (modelIds, areTypesAllowed) => {
      const allowedTypes = {};

      modelIds.forEach(modelId => {
        const modelAllowedTypes = {};
        Object.values(_pick(params, chatGalleryQueryParams)).forEach(value => {
          if (Array.isArray(value)) {
            value.forEach(val => { modelAllowedTypes[val] = areTypesAllowed; });
          } else {
            modelAllowedTypes[value] = areTypesAllowed;
          }
        });
        allowedTypes[modelId] = modelAllowedTypes;
      });

      dispatch(actionSetAllowedTypes(allowedTypes));
    };

    return apiClientHelper.get(
      `models/search/${params.page || 1}`,
      {
        params: removeBlankFields({
          limit: getModelLimit(getState()),
          ..._omit(params, [
            'languages',
            'sexPreferences',
            'saleItems',
            'orientations',
            'hairs',
            'heights',
            'bodies',
            'breasts',
            'ethnics',
            'ratings',
          ]),
          'languages[]': params.languages,
          'sexPreferences[]': params.sexPreferences,
          'saleItems[]': params.saleItems,
          'orientations[]': params.orientations,
          'hairs[]': params.hairs,
          'heights[]': params.heights,
          'bodies[]': params.bodies,
          'breasts[]': params.breasts,
          'ethnics[]': params.ethnics,
          'ratings[]': params.ratings,
        }),
        retry: true,
      },
      ITEMS_SCHEMA
    ).then(
      ({ result, entities }) => {
        const items = entities.items || {};

        const { models, statuses } = getModelsAndStatuses(entities.items);

        dispatch(actionAddModels(models));
        dispatch(actionSetModelChatStatuses(statuses));
        if (isHomePage) {
          setAllowedTypes(result.items, true);
        }

        const setModelIds = () => {
          if (!params.modelId) {
            if (isHomePage) {
              setModelOrder(dispatch, getState, getCookie, result.items, result);
            } else {
              dispatch(actionSetModelIds(result));
            }
          }
        };

        if (!dom.isBrowser()) {
          setModelIds();

          return dispatch(actionLoadModelsSuccess());
        }

        // Preload model photo thumbs
        const promises = Object.keys(items).map(id => preloadImage(items[id].imageUrl));

        return Promise.all(promises).then(() => {
          if (preserve) {
            dispatch(actionAddModelIds(result));
          } else {
            setModelIds();
          }

          dispatch(actionLoadModelsSuccess());
        });
      },
      error => {
        if (params.modelId) {
          setAllowedTypes([params.modelId], false);
        }

        dispatch(actionLoadModelsFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);

export const actionLoadModelsForSearchRequest = () => (
  (dispatch, getState, { history: { location: { search } } }) => (
    dispatch(actionLoadModelsRequest(url.parseQuery(search)))
  )
);
