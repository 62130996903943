import { combineReducers } from 'redux';

import {
  ACTION_LOAD_USER_NOTE__SUCCESS,
  ACTION_LOAD_USER_NOTE__FAIL,
} from '../actionTypes';


const data = (state = {}, action) => {
  switch (action.type) {
    case ACTION_LOAD_USER_NOTE__SUCCESS:
      return action.payload.userNote;

    case ACTION_LOAD_USER_NOTE__FAIL:
      return {};

    default:
      return state;
  }
};

export default combineReducers({
  data,
});
