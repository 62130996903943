export const ACCOUNT_TAB__ACCOUNT = 'account';
export const ACCOUNT_TAB__BLUE_CHECK = 'blueCheck';
export const ACCOUNT_TAB__PREFERENCES = 'preferences';
export const ACCOUNT_TAB__PRESENTATION = 'presentation';
export const ACCOUNT_TAB__PRIVACY = 'privacy';
export const ACCOUNT_TAB__PROFILE = 'profile';
export const ACCOUNT_TAB__PROFILE_PHOTO = 'profilePhoto';

export const BLOG_TAB__BLUE_CHECK = 'blueCheck';
export const BLOG_TAB__COVER_PHOTO = 'coverPhoto';
export const BLOG_TAB__POSTS = 'posts';
export const BLOG_TAB__PROFILE = 'profile';
export const BLOG_TAB__PROFILE_PHOTO = 'profilePhoto';

export const blogTabs = [
  BLOG_TAB__COVER_PHOTO,
  BLOG_TAB__PROFILE_PHOTO,
  BLOG_TAB__PROFILE,
  BLOG_TAB__BLUE_CHECK,
  BLOG_TAB__POSTS,
];

export const NOTIFICATION_TAB__PUSH = 'push';
export const NOTIFICATION_TAB__TELEGRAM = 'telegram';

export const notificationTabs = [
  NOTIFICATION_TAB__TELEGRAM,
  NOTIFICATION_TAB__PUSH,
];

export const PRIVACY_TAB__BANNED_USERS = 'bannedUses';
export const PRIVACY_TAB__BLACK_LIST = 'blackList';
export const PRIVACY_TAB__GEO = 'geo';

export const privacyTabs = [
  PRIVACY_TAB__GEO,
  PRIVACY_TAB__BANNED_USERS,
  PRIVACY_TAB__BLACK_LIST,
];

export const WEBCAM_TAB__EXTERNAL_APPLICATION = 'externalApplication';
export const WEBCAM_TAB__VIDEOCHAT = 'videochat';

export const webcamTabs = [
  WEBCAM_TAB__VIDEOCHAT,
  WEBCAM_TAB__EXTERNAL_APPLICATION,
];
