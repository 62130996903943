import { combineReducers } from 'redux';

import {
  ACTION_LOAD_TICKET_URL__REQUEST,
  ACTION_LOAD_TICKET_URL__SUCCESS,
  ACTION_LOAD_TICKET_URL__FAIL,
} from '../actionTypes';


const loadTicketUrlError = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_TICKET_URL__REQUEST:
    case ACTION_LOAD_TICKET_URL__SUCCESS:
      return null;

    case ACTION_LOAD_TICKET_URL__FAIL:
      return action.payload.error;

    default:
      return state;
  }
};

export default combineReducers({
  loadTicketUrlError,
});
