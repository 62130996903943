import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { refPropType } from '../../../propTypes/ref';
import { langPropType } from '../../propTypes';

import {
  LANG__EN,
  LANG__IT,
  LANG__ES,
  LANG__DE,
  LANG__FR,
  LANG__GR,
  LANG__RU,
} from '../../constants';

import styles from './styles/index.css.json';


export const langToClassNameMap = {
  [LANG__EN]: styles.icon_en,
  [LANG__IT]: styles.icon_it,
  [LANG__ES]: styles.icon_es,
  [LANG__DE]: styles.icon_de,
  [LANG__FR]: styles.icon_fr,
  [LANG__GR]: styles.icon_gr,
  [LANG__RU]: styles.icon_ru,
};

const propTypes = {
  lang: langPropType.isRequired,
  innerRef: refPropType,
  className: PropTypes.string,
};

const Language = ({
  lang,
  innerRef = null,
  className = null,
  ...rest
}) => (
  <span
    {...rest}
    ref={innerRef}
    className={classnames(langToClassNameMap[lang], className)}
  />
);

Language.propTypes = propTypes;

export default Language;
