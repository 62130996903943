import { dom } from 'react-redux-app/lib/modules/core/utils';
import preloadImage from '../../core/utils/preloadImage';
import { getFilter } from '../../core/loadableItems/utils';

import {
  ACTION_LOAD_PRIVATE_PHOTOS__REQUEST,
  ACTION_LOAD_PRIVATE_PHOTOS__SUCCESS,
  ACTION_LOAD_PRIVATE_PHOTOS__FAIL,

  ACTION_ADD_PRIVATE_PHOTOS,
  ACTION_SET_PRIVATE_PHOTOS,

  ACTION_SET_PRIVATE_PHOTOS_PRESERVE,

  ACTION_LOAD_PRIVATE_PHOTO_CATEGORIES__REQUEST,
  ACTION_LOAD_PRIVATE_PHOTO_CATEGORIES__SUCCESS,
  ACTION_LOAD_PRIVATE_PHOTO_CATEGORIES__FAIL,
} from '../actionTypes/privatePhoto';

import { PAGINATION_MODE__SCROLL } from '../../core/loadableItems/constants';

import { ITEMS_SCHEMA } from '../../core/schemas/items';

import { getPrivatePhotoPaginationMode } from '../selectors/privatePhoto';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddSaleItems } from './index';


const actionLoadPrivatePhotoCategoriesSuccess = categories => ({
  type: ACTION_LOAD_PRIVATE_PHOTO_CATEGORIES__SUCCESS,
  payload: { categories },
});

const actionLoadPrivatePhotoCategoriesFail = error => ({
  type: ACTION_LOAD_PRIVATE_PHOTO_CATEGORIES__FAIL,
  payload: { error },
});

export const actionLoadPrivatePhotoCategoriesRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_PRIVATE_PHOTO_CATEGORIES__REQUEST,
    });

    return apiClientHelper.get(
      'private_photo/categories',
      { retry: true }
    ).then(
      ({ categories }) => {
        dispatch(actionLoadPrivatePhotoCategoriesSuccess(categories));
      },
      error => {
        dispatch(actionLoadPrivatePhotoCategoriesFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);

const actionAddPrivatePhotos = privatePhotos => ({
  type: ACTION_ADD_PRIVATE_PHOTOS,
  payload: { privatePhotos },
});

export const actionSetPrivatePhotos = privatePhotos => ({
  type: ACTION_SET_PRIVATE_PHOTOS,
  payload: { privatePhotos },
});

export const actionSetPrivatePhotosPreserve = () => ({
  type: ACTION_SET_PRIVATE_PHOTOS_PRESERVE,
});

const actionLoadPrivatePhotosSuccess = () => ({
  type: ACTION_LOAD_PRIVATE_PHOTOS__SUCCESS,
});

const actionLoadPrivatePhotosFail = error => ({
  type: ACTION_LOAD_PRIVATE_PHOTOS__FAIL,
  payload: { error },
});

export const actionLoadPrivatePhotosRequest = () => (
  (dispatch, getState, { apiClientHelper, history: { location } }) => {
    const preserve = getPrivatePhotoPaginationMode(getState()) === PAGINATION_MODE__SCROLL;

    dispatch({
      type: ACTION_LOAD_PRIVATE_PHOTOS__REQUEST,
    });

    const {
      nick,
      page = 1,
      category,
      orderBy,
      limit = 114,
    } = getFilter(location);

    const params = { limit };
    if (nick) {
      params.nick = nick;
    }
    if (category) {
      params.category = category;
    }
    if (orderBy) {
      params.orderBy = orderBy;
    }

    return apiClientHelper.get(
      `private_photo/list/${page}`,
      { params, retry: true },
      ITEMS_SCHEMA
    ).then(
      ({ result: privatePhotos, entities }) => {
        const items = entities.items || {};

        dispatch(actionAddSaleItems(items));

        if (!dom.isBrowser()) {
          dispatch(actionSetPrivatePhotos(privatePhotos));

          return dispatch(actionLoadPrivatePhotosSuccess());
        }

        // Preload privatePhoto thumbs
        const promises = Object.keys(items).map(id => preloadImage(items[id].thumbUrl));

        return Promise.all(promises).then(() => {
          if (preserve) {
            dispatch(actionAddPrivatePhotos(privatePhotos));
          } else {
            dispatch(actionSetPrivatePhotos(privatePhotos));
          }

          dispatch(actionLoadPrivatePhotosSuccess());
        });
      },
      error => {
        dispatch(actionLoadPrivatePhotosFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
