import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Link } from 'react-redux-app/lib/modules/link/components';

import styles from './styles/index.css.json';


const propTypes = {
  className: PropTypes.string.isRequired,
};

const LegalLogos = ({ className }) => (
  <div className={classnames(styles.legalLogos, className)}>
    <Link
      isExternal
      className={classnames(styles.legalLogo, styles.rta)}
      to="https://www.rtalabel.org/"
      rel="noopener noreferrer nofollow"
    />

    {/*
    <Link
      isExternal
      className={classnames(styles.legalLogo, styles.asacp)}
      to="https://www.asacp.org/?content=validate&ref="
      rel="noopener noreferrer nofollow"
    />
    */}
  </div>
);

LegalLogos.propTypes = propTypes;

export default LegalLogos;
